import React from 'react'
import { Grid } from '@mui/material'
import { Card, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'

const {
  form: {
    field: {
      CngDateField
    }
  }, CngGridItem
} = components


function BlanketPeriodComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  return (
    <Card >
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', width: 'auto' }}>
        <Grid item sm={12}>
          <Grid xs={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.blanketPeriodInformation}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', marginTop: '-25px', width: 'auto' }}>
        <CngGridItem xs={12} sm={4}>
          {FtaTranslatedTextsObject.blanketLabelFL}
        </CngGridItem>
        <CngGridItem xs={12} sm={4}>
          <CngDateField
            name='blanketStartDate'
            label={FtaTranslatedTextsObject.startDateFL}
            format='DD-MMM-YYYY HH:mm'
            toServerDataFormat='DD-MMM-YYYY HH:mm'
            required
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={4}>
          <CngDateField
            name='blanketEndDate'
            label={FtaTranslatedTextsObject.endDateFL}
            format='DD-MMM-YYYY HH:mm'
            toServerDataFormat='DD-MMM-YYYY HH:mm'
            required
          />
        </CngGridItem>
      </Grid>
    </Card>
  )
}

export default BlanketPeriodComponent