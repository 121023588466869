import React, { useEffect, useState } from 'react'

import { components, useServices } from 'cng-web-lib'
import moment from "moment";
import { Grid } from '@mui/material'
import { Card } from '@material-ui/core'

import html2pdf from 'html2pdf.js'
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import GeneratedCertificateViewComponent from '../components/view/GeneratedCertificateViewComponent'
import TradeMgmtApiUrls from '../../../../apiUrls/TradeMgmtApiUrls'

import GeneratedCertificateButtonSection from '../components/GeneratedCertificateButtonSection';

const {
  table: { useDefaultNotification }
} = components

function FtaSelfCertificateHistoryViewPage(props) {

  const { error: showErrorNotification } = useDefaultNotification(props.showNotification)

  const [displayDate, setDisplayDate] = useState('')
  const [displayTime, setDisplayTime] = useState('')
  const [certificateId, setCertificateId] = useState('')
  const [signatureImage, setSignatureImage] = useState('')
  const [certificateDetails, setCertificateDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const getDate = (dateTimeStr) => {
    const dateObject = new Date(dateTimeStr)
    let now = dateObject
    let formatDate = moment(now.getTime()).format('dddd, DD MMM yyyy');
    setDisplayDate(formatDate)
  }

  const getTime = (dateTimeStr) => {
    const dateObject = new Date(dateTimeStr)
    let now = dateObject
    let formatTime = moment(now.getTime()).format('hh:mm A');
    setDisplayTime(formatTime)
  }

  let certificateHistoryId = props?.history?.location?.state?.detailsData?.certificateHistoryId
  const { securedSendRequest } = useServices()


  const fetchGeneratedCertificated = (certificateHistoryId) => {
    const onSuccess = (response) => {
      if (response.data?.status === 200) {
        getDate(response?.data?.results?.createdDate)
        getTime(response?.data?.results?.createdDate)
        setCertificateDetails(response?.data?.results)
        setCertificateId(response?.data?.results?.certificateOfOrigin.certificateId)
        setSignatureImage(response?.data?.results?.signature?.signatureUrl)
      } else {
        onError('error')
      }
    }

    const onError = (error) => {
      //show notification
      console.log('error')
      showErrorNotification('Error. Kindly contact Admin.')
    }

    const onComplete = () => {
      console.log('oncomplete')
      setIsLoading(false)
    }

    const request = {
      'certificateHistoryId': certificateHistoryId
    }

    securedSendRequest.execute(
      'POST',
      TradeMgmtApiUrls.SELF_CERTIFICATE_HISTORY_GET_CERTIFICATE,
      request,
      onSuccess,
      onError,
      onComplete
    )

  }

  const downloadPDFOnClick = () => {
    let element = document.getElementById('certificateToPrint');

    let opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: `${certificateId}.pdf`,
      image: {
        type: 'jpeg',
        quality: 1
      },
      html2canvas: {
        scale: 2,
        imageTimeout: 0,
        useCORS: true
      },
      jsPDF: {
        unit: 'in',
        format: 'a2',
        orientation: 'portrait',
        precision: 6
      }
    };

    html2pdf().set(opt).from(element).save()

  }

  useEffect(() => {
    setIsLoading(true)
    fetchGeneratedCertificated(certificateHistoryId)
  }, [])


  return (
    <>
      <Card>
        <Grid item xs={12}>
          <CngBackdrop loading={isLoading} />
        </Grid>
        <Grid container>
          <div id={'certificateToPrint'} >
            <GeneratedCertificateViewComponent
              selfCertificatePayload={certificateDetails}
              signatureImage={signatureImage}
              displayDate={displayDate}
              displayTime={displayTime}
            />
          </div>

        </Grid>

        <Grid style={{ padding: '25px' }}>
          <GeneratedCertificateButtonSection
            downloadPDFOnClick={downloadPDFOnClick}
          />
        </Grid>
      </Card>
    </>
  )
}


export default FtaSelfCertificateHistoryViewPage