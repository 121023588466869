import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import FormProperties from './FormProperties'
import pathMap from 'src/paths/PathMap_TmpAdmin'


const {
  form: { CngViewForm },
  button: { CngSecondaryButton }
} = components

function ViewPage({ showNotification, history }) {
  const { id } = useParams()
  return (
    <>
      <CngViewForm
        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
        showNotification={showNotification}
        history={history}
        bodySection={
          <>
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
              id={id}
            />
            <Grid container spacing={3}>
              <Grid item>
                <CngSecondaryButton
                  onClick={() => { history.push(pathMap.COMPANY_PROFILE_LIST_VIEW) }}>
                  {'Back'}
                </CngSecondaryButton>
              </Grid>
            </Grid>

          </>
        }
        formikProps={FormProperties.formikProps}
        toClientDataFormat={FormProperties.toClientDataFormat}
        toServerDataFormat={FormProperties.toServerDataFormat}
        fetch={{
          id: id,
          url: TmpCompanyProfileApiUrls.LIST
        }}
      />
    </>
  )
}

export default ViewPage
