import React, { useState, useEffect, useMemo, createContext, useContext } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSnackbar } from 'notistack'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  BasePage as CngBasePage,
  components, useServices, constants
} from 'cng-web-lib'

import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeManagement'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'

import {
  CodeDisplay, CompanyInfo,
  DocumentInfoForm,
  Field, FormActions, FormCard,
  SearchDialog, SectionHeader
} from './components'
import {
  PROD_INIT, COMPANY_REF_INIT, DUPE_KEY, STATE_KEY,
  PROD_SCHEMA as validationSchema,
  parseFormData, tmpFilterCode,
  COLUMN_BUILDER_URLS, useColumnBuilder,
  useBOMComponents, tmpFilterStatus
} from './helpers'
import { Page } from '@react-pdf/renderer'
import { Skeleton } from '@material-ui/lab'

const {
  button: { CngButton },
  form: {
    CngForm,
    field: {
      CngLookupAutocompleteField,
      CngServerSideAutocompleteField
    }
  }
} = components
const { FormState } = constants

const useStyles = makeStyles(theme => ({
  actions: {
    '& > *:first-child': {
      marginRight: 'auto'
    },
    '& .ng-button-outlined': {
      borderColor: theme.palette.background.ctaBtnActiveBorder,
      padding: theme.spacing(.375, 3.5),
      minWidth: 'auto',
      '&.MuiButton-outlinedPrimary': {
        color: theme.palette.primary.main
      },
      '&.MuiButton-colorInherit': {
        color: theme.palette.text.error,
        '&:active, &:focus': {
          background: theme.palette.text.error + '1f'
        }
      },
      '&.Mui-disabled': {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled
      }
    }
  }
}))

const ProductContext = createContext(null)

function ProductForm(props) {
  const { securedSendRequest } = useServices()
  const classes = useStyles()
  const { productId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const {
    editing, setEditing,
    loading, setLoading,
    creating, setCreating,
    submitting, setSubmitting
  } = useContext(ProductContext)
  const { reset, getValues, setValue, watch } = useFormContext()
  const { loading: colLoading, COLS_BOM } = useColumnBuilder([
    { url: COLUMN_BUILDER_URLS.tmp, payload: "TMP_COMPONENT_MATERIAL" },
    { url: COLUMN_BUILDER_URLS.getTmp('GET_COUNTRY'), payload: 'COUNTRY' }
  ])
  const { loading: bomLoading } = useBOMComponents()

  const [dialog, setDialog] = useState({})

  const watchComps = watch(['REF_components', 'bomDetail.componentDetail'])
  const bomData = useMemo(
    () => (getValues('REF_components') || []).filter(x => x.attached && x.quantity)
      .map((x, i) => ({ ...x, displayId: i + 1 })),
    [getValues('REF_components')]
  )

  const fetchProduct = (id, isSubscribed, postFetch) => {
    setLoading(true)
    securedSendRequest.execute("POST",
      `${TradeMgmtApiUrls.GET_PRODUCT}/${id}`,
      {},
      res => {
        if (res.data && isSubscribed) {
          if (res.data.errorMessages)
            throw res.data.errorMessages
          let fd = parseFormData(res.data.results, { REF_company: COMPANY_REF_INIT })
          setValue('hsCodeAndDescription', fd.hsCodeAndDescription)
          reset(fd)
          postFetch()
        }
      }, err => {
        if (typeof err !== 'array')
          err = [err]
        err.forEach(e => e instanceof String && enqueueSnackbar(e, { variant: 'error' }))
      }, () => setLoading(false)
    )
  }



  useEffect(() => {
    let isSubscribed = true
    let state = localStorage.getItem(STATE_KEY)
    if (state)
      state = atob(state)

    // check if new or existing
    if (productId !== undefined) {
      fetchProduct(
        productId, isSubscribed,
        () => setEditing(state === "EDIT")
      )
    } else {
      // check if duplicating
      if (state && !isNaN(parseInt(state))) {
        fetchProduct(state, isSubscribed, () => {
          setEditing(true)
          setCreating(true)
        })
      } else {
        let dupe = localStorage.getItem(DUPE_KEY)
        if (dupe) {
          dupe = JSON.parse(atob(dupe))
          reset(dupe)
          localStorage.removeItem(DUPE_KEY)
        }
        setEditing(true)
        setCreating(true)
      }
    }
    localStorage.removeItem(STATE_KEY)

    return () => {
      setEditing(false)
      setCreating(false)
      setLoading(false)
      isSubscribed = false
    }
  }, [])

  const handleClearBom = () => setValue('bomDetail.componentDetail', [])

  const handleCloseDialog = () => setDialog({})

  const handleOpenBom = () => setDialog({
    open: true,
    title: 'Attach component'
  })

  return (
    <Box component={Card}>
      <CardContent>
        <SectionHeader title="Product information">
          <Box component="span" color="text.error">*</Box>
          Mandatory fields
        </SectionHeader>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* <Field label="HS code & description"
              name="hsMasterId"
              required
              component={CngLookupAutocompleteField}
              lookupPageableProps={{
                url: TradeMgmtApiUrls.GET_HS_LIST,
                label: 'hsCodeAndDescription',
                value: 'id',

              }}
            /> */}
            <Field label="HS code & description"
              name={`hsMasterId`}
              key={`hsMasterIdKey`}
              required
              component={CngServerSideAutocompleteField}
              minFilterChars={-1}
              debounceInterval={1000}
              searchableFields={['hsCodeAndDescription']}
              lookupPageableProps={{
                url: TradeMgmtApiUrls.GET_HS_LIST,
                value: 'id',
                label: 'hsCodeAndDescription',
                pageSize: 10,
              }}
              getOptionSelected={(opt, val) => opt.value === val.value}
              render={() => getValues('hsCodeAndDescription')}
              lookupProps={{ label: 'data' }}
              defaultValue={getValues('hsCodeAndDescription')}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field label="Material no. / SKU"
              required
              name="materialSkuNo"
              disabled={!creating}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Field label="Product description"
              name="productDescription"
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field label="Model"
              name="model"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field label="Base unit"
              name="baseQuantity"
              required
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field label="UOM"
              name="uomId"
              required
              component={CngLookupAutocompleteField}
              lookupProps={{
                url: TradeMgmtApiUrls.GET_CODEMASTER,
                label: c => `(${c.code}) ${c.name}`,
                value: 'id',
                filters: [tmpFilterCode('uom'), tmpFilterStatus]
              }}
              disableActive
              render={() => <CodeDisplay chip={getValues('uomCode')}>
                {getValues('uomName')}
              </CodeDisplay>}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            {/* <Field label="Country of manufacture"
              name="countryOfManufacture"
              required
              component={CngLookupAutocompleteField}
              lookupProps={{
                url: TradeMgmtApiUrls.GET_COUNTRY,
                label: 'name',
                value: 'id'
              }}
            /> */}
            <Field
              component={CountryAutocompleteField}
              label="Country of manufacture"
              name='countryOfManufactureCode'
              required
              accessor='countryList'
              url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
              lookupProps={{
                label: 'name',
                value: 'code',
                dataAccessor: 'content',
                url: TradeMgmtApiUrls.GET_TMP_COUNTRY
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}> 
            <Field label="Ex Works (EXW) price"
              name="exworkPrice"
              required
              type="number"
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <Field label="Currency"
              name="currency"
              required
              component={CngLookupAutocompleteField}
              lookupProps={{
                url: TradeMgmtApiUrls.GET_CURRENCY,
                label: c => `(${c.code}) ${c.name}`,
                value: 'code'
              }}
              render={(val, lkd) => <CodeDisplay chip={val}>
                {lkd.name}
              </CodeDisplay>}
            />
          </Grid>
        </Grid>

        {/* Product Value info*/}
        <SectionHeader title="Product value" mt={5}>
          Optional fields
        </SectionHeader>

        <Box component={Grid} container spacing={1} pb={4}>
          <Grid item xs={3}>
            <Field label="Ex Works (EXW) price"
              name="exworkPrice"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <Field label="Free on Board (FOB) Price"
              name="fobPrice"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <Field label="Transaction Value"
              name="transactionValue"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <Field label="Net Cost"
              name="netCosts"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <Field label="Total Cost"
              name="totalCosts"
              type="number"
            />
          </Grid>
        </Box>

        {/* Company info */}
        <SectionHeader title="Principal information" mt={5}>
          <Box component="span" color="text.error">*</Box>
          Mandatory fields
        </SectionHeader>
        <CompanyInfo />


        {/* Document info */}
        <SectionHeader title="Document information" mt={5}>
          <Box component="span" color="text.error">*</Box>
          Mandatory fields
        </SectionHeader>
        <FormCard title="Bill of Materials"
          subtitle={
            `${getValues('bomDetail.componentDetail').length} entries`
          }
          searchText="Search in your entries"
          toolbar={editing && <>
            <Grid item>
              <CngButton size="small" color="secondary"
                startIcon={<FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} />}
                onClick={handleClearBom}
              >
                <Box fontWeight="fontWeightBold" px={1}>Clear all</Box>
              </CngButton>
            </Grid>
            <Grid item>
              <CngButton size="small" color="secondary"
                startIcon={<FontAwesomeIcon icon={['fal', 'paperclip-vertical']} />}
                onClick={handleOpenBom}
              >
                <Box fontWeight="fontWeightBold" px={1}>Attach component</Box>
              </CngButton>
            </Grid>
          </>}
          tableData={{
            data: bomData,
            cols: COLS_BOM,
            loading: colLoading || dialog.open,
          }}
        >
          <Box component={Grid} container spacing={1} pb={4}>
            <Grid item xs={12}>
              <Field label="BOM name"
                name="bomDetail.bomName"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Field label="Profit"
                name="bomDetail.profit"
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Field label="Other cost"
                name="bomDetail.otherCost"
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Field label="Direct labor cost"
                name="bomDetail.directLaborCost"
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Field label="Direct overhead cost"
                name="bomDetail.directOverheadCost"
                type="number"
              />
            </Grid>
          </Box>
        </FormCard>

        {/* Suppoorting Document */}
        <DocumentInfoForm
          id={productId}
        />


      </CardContent>
      <Box component={CardActions} borderTop={1} borderColor="#E4EBF6A3" className={classes.actions}>
        <FormActions />
      </Box>
      <SearchDialog
        dialogTitle={dialog.title}
        open={dialog.open || false}
        onClose={handleCloseDialog}
      />

    </Box>
  )
}

function PageWrapper() {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  const [editing, setEditing] = useState(false)
  const [creating, setCreating] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <CngBasePage
      moduleTitle={'Export'}
      renderPage={(showSnackbar) => (
        <ProductContext.Provider value={{
          editing, setEditing,
          loading, setLoading,
          creating, setCreating,
          submitting, setSubmitting
        }}>
          <CngForm
            shouldAlwaysUpdateRule
            fieldLevel='form'
            hookFormProps={{
              initialValues: PROD_INIT,
              validationSchema,
              mode: 'all',
            }}
            renderBodySection={() => <ProductForm />}
            renderButtonSection={() => <></>}
            formState={FormState.COMPLETED}
            formCode='create'
          />
        </ProductContext.Provider>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}




export { PageWrapper as ProductPage, ProductContext }
