import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PropTypes from 'prop-types'
import { components, constants, useServices } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'

import FormProperties from './FormProperties'
import TradeMgmtApiUrls from '../../../../apiUrls/TradeMgmtApiUrls'

import moment from "moment";
import { convertDataToAPIFromFormData } from '../util/Utils'

StepperForm.propTypes = {
  showNotification: PropTypes.func,
  actionType: PropTypes.string
}

const steps = ['Provide details', 'Review details', 'Finish']
const {
  FormState
} = constants



function StepperForm(props) {
  const { showNotification, actionType } = props
  const { securedSendRequest } = useServices()
  const history = useHistory()

  const [generateSelfCertificate, setGenerateSelfCertificate] = useState()
  const [selfCertificateData] = useState()
  const [certificateId, setCertificateId] = useState('')
  const [ftaCode, setFtaCode] = useState('')
  const [ftaName, setFtaName] = useState('')
  const [certifierInformation, setCertifierInformation] = useState([])
  const [signatureDetails, setSignatureDetails] = useState([])
  const [skuMaterials, setSkuMaterials] = useState([])
  const [signatureImage, setSignatureImage] = useState('')

  const [displayDate, setDisplayDate] = useState('')
  const [displayTime, setDisplayTime] = useState('')

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)


  const certifyData = history?.location?.state?.detailsData?.selfCertify
  const principalId = history?.location?.state?.detailsData?.principalId


  const useStyles = makeStyles({
    secondContainer: {
      paddingBottom: 20,
      paddingRight: 0,
      paddingLeft: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '110%',
      height: '40'
    },
    stepLabel: {
      fontSize: '1.2rem !important',
      fontWeight: 'bolder'
    }
  })

  const classes = useStyles()


  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }


  const getDate = (dateTimeStr) => {
    const dateObject = new Date(dateTimeStr)
    let now = dateObject
    let formatDate = moment(now.getTime()).format('dddd, DD MMM yyyy');
    setDisplayDate(formatDate)
  }

  const getTime = (dateTimeStr) => {
    const dateObject = new Date(dateTimeStr)
    let now = dateObject
    let formatTime = moment(now.getTime()).format('hh:mm A');
    setDisplayTime(formatTime)
  }

  const ftaDetails = {
    ftaCode: ftaCode,
    ftaName: ftaName
  }

  const prepareSelfCertificatePayload = (formData) => {

    return convertDataToAPIFromFormData(formData, ftaDetails, certificateId, principalId,
      certifyData, skuMaterials, signatureImage)
  }

  const generateCertificate = (formData) => {
    const payloadReq = prepareSelfCertificatePayload(formData)
    const onSuccess = (response) => {
      if (response.data?.status === 200) {
        const dateString = response?.data?.results?.createdDate
        getDate(dateString)
        getTime(dateString)
        showSuccessNotification('Certificate Generated Successfully.')
        handleNext()
      }

    }

    const onError = (error) => {
      showErrorNotification('Error. Please Kindly contact admin for support.')
      console.log(error)

    }

    const onComplete = () => {
      console.log('Complete.')
    }

    securedSendRequest.execute(
      'POST',
      TradeMgmtApiUrls.GENERATE_CERTIFICATE,
      payloadReq,
      onSuccess,
      onError,
      onComplete
    )

  }

  const onSubmitForGenerate = (formData) => {
    generateCertificate(formData)
  }

  const {
    form: {
      CngForm
    },
    table: { useDefaultNotification }
  } = components

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const fetchData = (certifyData) => {
    const onSuccess = (response) => {
      if (response.data?.status === 200) {
        setGenerateSelfCertificate(response.data?.results)
        setCertificateId(response.data?.results?.certificateId)
        setFtaCode(response.data?.results?.ftaCode)
        setFtaName(response.data?.results?.ftaName)
        setCertifierInformation(response.data?.results?.certifierInformation)
        setSignatureDetails(response.data?.results?.signatureDetails)
        setSkuMaterials(response.data?.results?.certificateItemDetails)
        setSignatureImage(response.data?.results?.signatureDetails.signatureUrl)
      } else {
        setGenerateSelfCertificate([])
        setCertificateId('')
        setFtaCode('')
        setFtaName('')
        setCertifierInformation({})
        setSignatureDetails({})
        setSkuMaterials([])
        setSignatureImage('')
      }
    }

    const onError = (error) => {
      showErrorNotification('Error. Please Kindly contact admin for support.')
      console.log(error)
      setGenerateSelfCertificate([])
    }

    const onComplete = () => {
      console.log('oncomplete.')
      setIsLoading(false)
    }

    const request = {
      'certificateIds': certifyData,
      'principalId': principalId
    }

    securedSendRequest.execute(
      'POST',
      TradeMgmtApiUrls.CERTIFY_CERTIFICATE,
      request,
      onSuccess,
      onError,
      onComplete
    )

  }

  useEffect(() => {
    setIsLoading(true)
    fetchData(certifyData)
  }, [])


  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <CngBackdrop loading={isLoading} />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Grid item xs={10} sm={8}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CngForm
          formState={FormState.COMPLETED}
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          renderBodySection={
            () => <FormProperties.Fields
              activeStep={activeStep}
              steps={steps}
              handleNext={handleNext}
              handleBack={handleBack}
              actionType={actionType}
              showNotification={showNotification}
              generateSelfCertificate={generateSelfCertificate}
              principalId={principalId}
              selfCertifyIds={certifyData}
              certificateId={certificateId}
              ftaCode={ftaCode}
              ftaName={ftaName}
              certifierInformation={certifierInformation}
              signatureDetails={signatureDetails}
              skuMaterials={skuMaterials}
              signatureImage={signatureImage}
              setSignatureImage={setSignatureImage}
              displayDate={displayDate}
              displayTime={displayTime}
            />
          }
          hookFormProps={{
            initialValues: selfCertificateData,
            validationSchema: FormProperties.hookFormProps.makeValidationSchema,
            mode: 'all',
            onSubmit: onSubmitForGenerate
          }}
          renderButtonSection={() => undefined}
        />
      </Grid>
    </Grid>


  )
}

export default StepperForm