import { Box, Card, Collapse, Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'

const {
  CngGridItem,
} = components


export const certificateItemsTable = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: '10px'
  },
  thTitle: {
    padding: theme.spacing(2),
    backgroundColor: '#EDEDF2'
  },
  td: {
    padding: theme.spacing(2),
  },
  evenColumn: {
    padding: '5px',
    backgroundColor: '#E6EFFF',
  },
  oddColumn: {
    padding: '5px',
    backgroundColor: '#F7FAFF',
  },
  descriptionOdd: {
    padding: '5px',
    backgroundColor: '#FFFFFF',
  },
  descriptionEven: {
    padding: '5px',
    backgroundColor: '#F5F5FA'
  },
  transformArrow: {
    transform: 'rotate(180deg)'
  }
}))

function CertificateItemsDetails(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  const classes = certificateItemsTable()
  const { sku, id } = props
  const [isExpanded, setIsExpanded] = useState(true)

  const expandSku = () => {
    setIsExpanded(!isExpanded)
  }



  return (
    <Grid container spacing={1} sx={{ margin: '0px' }} style={{ paddingBottom: '15px' }}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box>
            <Grid container className='accordian-header'>
              <CngGridItem xs={5} sm={5}>
                <Box p={1} fontSize='large' fontWeight='bolder'>
                  SKU {id}
                </Box>
              </CngGridItem>

              <Grid container xs={7} sm={7} justify="flex-end">
                <Box p={1} >
                  Material no. / SKU <b> {sku.materialSkuNo} </b>
                </Box>
                <IconButton aria-label="expand">
                  <KeyboardArrowUpIcon className={isExpanded ? classes.transformArrow : null}
                    onClick={() => expandSku()} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Collapse in={isExpanded} tiemout="auto" unmountOnExit>
            <Grid container spacing={1} xs={12} sm={12} style={{ padding: '10px' }}>
              <CngGridItem xs={12} sm={3}>
                <div className={`field-vertical--label-value`} style={{ marginInlineStart: '12px' }} >
                  <span className={`field-vertical--item`}>
                    <label className={`field-value font-16`}>{FtaTranslatedTextsObject.productDescriptionFL}</label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-label clamp-line-to-two font-14 pr-10`}  >
                      {sku.productDescription}
                    </label>
                  </span>
                </div>
              </CngGridItem>
              <CngGridItem xs={12} sm={3}>
                <div className={`field-vertical--label-value`} style={{ marginInlineStart: '12px' }} >
                  <span className={`field-vertical--item`}>
                    <label className={`field-value font-16`}>{FtaTranslatedTextsObject.hsCodeFL}</label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-label clamp-line-to-two font-14 pr-10`} >
                      {sku.hsCode}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={3}>
                <div className={`field-vertical--label-value`} style={{ marginInlineStart: '12px' }} >
                  <span className={`field-vertical--item`}>
                    <label className={`field-value font-16`}>{FtaTranslatedTextsObject.baseUnitFL}</label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-label clamp-line-to-two font-14 pr-10`} >
                      {sku.baseUnit}
                    </label>
                  </span>
                </div>
              </CngGridItem>

              <CngGridItem xs={12} sm={3}>
                <div className={`field-vertical--label-value`} style={{ marginInlineStart: '12px' }} >
                  <span className={`field-vertical--item`}>
                    <label className={`field-value font-16`}>{FtaTranslatedTextsObject.fobPriceFL}</label>
                  </span>

                  <span className={`field-vertical--item`}>
                    <label className={`field-label clamp-line-to-two font-14 pr-10`} >
                      {sku.fobPrice}
                    </label>
                  </span>
                </div>
              </CngGridItem>

            </Grid>
            <CngGridItem xs={12} sm={12}>
              <table className={classes.table}>
                <thead>
                  <th className={classes.thTitle}>Item #</th>
                  <th className={classes.thTitle}>Product Specific Rule</th>
                </thead>


                <tbody>
                  {sku?.productSpecificRules?.map((desc, id) => {
                    let prefixNum = id + 1
                    return (
                      <>
                        <tr>
                          <td className={prefixNum % 2 == 0 ? `${classes.evenColumn} ` : `${classes.oddColumn}`}>
                            <span style={{ paddingLeft: '10px' }}>
                              {id + 1}
                            </span>
                          </td>
                          <td className={prefixNum % 2 == 0 ? `${classes.descriptionEven}` : `${classes.descriptionOdd}`}>
                            <span style={{ paddingLeft: '10px' }}>
                              {desc}
                            </span>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </CngGridItem>

          </Collapse>
        </Card>
      </CngGridItem>
    </Grid >
  )
}

export default CertificateItemsDetails