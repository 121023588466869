import React, { useState, useEffect } from 'react'
import { Box, Card, Typography, Select, MenuItem, makeStyles } from '@material-ui/core'
import { Grid } from '@mui/material'
import { components } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'

EntityInformationDetails.propTypes = {}

const useStyles = makeStyles((theme) => ({
  titleSpanSection: {
    color: '#8996AF',
    fontSize: '12px',
    fontWeight: 'normal'
  }
}))

const {
  form: {
    field: {
      CngTextField,
      CngSwitchField,
      CngCheckboxField
    }
  }, CngGridItem
} = components


function EntityInformationDetails(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  const classes = useStyles()

  const [exporterChecked, setExporterChecked] = useState(false);
  const [importerChecked, setImporterChecked] = useState(false);
  const [isConfidentiality, setIsConfidentiality] = useState(false)
  const { getValues, setValue, trigger } = useFormContext()
  const [exporterIsDisabled, setExporterIsDisabled] = useState(false)
  const [producerIsDisabled, setProducerIsDisabled] = useState(false)
  const [importerIsDisabled, setImporterIsDisabled] = useState(false)
  const [certifierKey, setCertifierKey] = useState('')
  const [exporterKey, setExportedKey] = useState('')
  const [importerKey, setImporterKey] = useState('')
  const [producerSameAs, setProducerSameAs] = useState('CERTIFIER')
  const [producerSameAsChecked, setProducerSameAsChecked] = useState(false)
  const [producerKey, setProducerKey] = useState('')
  const [confidentialityDisabled, setConfidentialityDisabled] = useState(false)



  const {
    certifierInformation
  } = props

  const exporterSameDetailsOnTrigger = (e) => {
    setExporterChecked(e.currentTarget.checked)
    if (!exporterChecked) {
      //disable
      setValue('exporterName', getValues('certifierName'))
      setValue('exporterJobTitle', getValues('certifierJobTitle'))
      setValue('exporterEmailAddress', getValues('certifierEmailAddress'))
      setValue('exporterContactNo', getValues('certifierContactNo'))
      setValue('exporterAddress', getValues('certifierAddress'))
      setExportedKey(getValues('certifierName'))
      setExporterIsDisabled(true)
    } else {
      //enable
      setExporterIsDisabled(false)
    }
    trigger('exporterName')
  }

  const importerSameDetailsOnTrigger = (e) => {
    setImporterChecked(e.currentTarget.checked)
    if (!importerChecked) {
      //disable
      setValue('importerName', getValues('certifierName'))
      setValue('importerJobTitle', getValues('certifierJobTitle'))
      setValue('importerEmailAddress', getValues('certifierEmailAddress'))
      setValue('importerContactNo', getValues('certifierContactNo'))
      setValue('importerAddress', getValues('certifierAddress'))
      setImporterKey(getValues('certifierName'))
      setImporterIsDisabled(true)
    } else {
      setImporterIsDisabled(false)
    }
    trigger('importerName')
  }

  const producerSameDetailsOnTrigger = (e) => {
    setProducerSameAsChecked(e.currentTarget.checked)
    if (!producerSameAsChecked) {
      if (producerSameAs === 'CERTIFIER') {
        setValue('producerName', getValues('certifierName'))
        setValue('producerJobTitle', getValues('certifierJobTitle'))
        setValue('producerEmailAddress', getValues('certifierEmailAddress'))
        setValue('producerContactNo', getValues('certifierContactNo'))
        setValue('producerAddress', getValues('certifierAddress'))
        setProducerKey(getValues('certifierName'))
      } else {
        //same as EXPORTER
        setValue('producerName', getValues('exporterName'))
        setValue('producerJobTitle', getValues('exporterJobTitle'))
        setValue('producerEmailAddress', getValues('exporterEmailAddress'))
        setValue('producerContactNo', getValues('exporterContactNo'))
        setValue('producerAddress', getValues('exporterAddress'))
        setProducerKey(getValues('exporterName'))
      }
      setProducerIsDisabled(true)
    } else {
      setProducerIsDisabled(false)
    }
    trigger('producerName')
  }


  const handleConfidentialityChange = (e) => {
    setIsConfidentiality(e.target.checked)
    if (!isConfidentiality) {
      setConfidentialityDisabled(true)
      setProducerIsDisabled(true)
    } else {
      setConfidentialityDisabled(false)
      setProducerIsDisabled(false)
    }
  }

  useEffect(() => {
    setValue('certifierName', certifierInformation.entityName)
    setValue('certifierJobTitle', certifierInformation.jobTitle)
    setValue('certifierEmailAddress', certifierInformation.emailAddress)
    setValue('certifierContactNo', certifierInformation.contactNo)
    setValue('certifierAddress', certifierInformation.address)
    setCertifierKey(certifierInformation.entityName)
  }, [certifierInformation])



  return (
    <>
      <Card style={{ padding: '0px' }}>
        <Grid container spacing={2} style={{ backgroundColor: '#EDEDF2', padding: '15px', wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Grid item sm={12}>
            <Grid xs={12}>
              <Typography variant='h5' className='font-bolder'>
                {FtaTranslatedTextsObject.certifierInformation}
              </Typography>
              <Box fontSize={10} paddingBottom={2}>
                <span style={{ color: 'red' }}>*</span>
                {'Mandatory fields'}
              </Box>
            </Grid>
          </Grid>

          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='certifierName'
              label={FtaTranslatedTextsObject.entityNameFL}
              key={'certifierKey_' + certifierKey + 'name'}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='certifierJobTitle'
              label={FtaTranslatedTextsObject.jobTitleFL}
              key={'certifierKey_' + certifierKey + 'jobTitle'}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='certifierEmailAddress'
              label={FtaTranslatedTextsObject.emailAddressFL}
              key={'certifierKey_' + certifierKey + 'emaillAddress'}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='certifierContactNo'
              label={FtaTranslatedTextsObject.contactNoFL}
              key={'certifierKey_' + certifierKey + 'contactNo'}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <CngTextField
              name='certifierAddress'
              label={FtaTranslatedTextsObject.addressFL}
              key={'certifierKey_' + certifierKey + 'Address'}
            />
          </CngGridItem>
        </Grid>

        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }}>
          <Grid item sm={12} style={{ display: 'flex' }}>
            <Grid xs={6}>
              <Typography variant='h5' className='font-bolder'>
                {FtaTranslatedTextsObject.exporterInformation}
                <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.exporterIfDifferentFL}</span>
              </Typography>

              <Box fontSize={10} paddingBottom={2}>
                <span style={{ color: 'red' }}>*</span>
                {'Mandatory fields'}
              </Box>
            </Grid>

            <Grid xs={6} style={{ textAlign: 'right', direction: 'rtl' }}>
              <CngCheckboxField
                name='exporterSameAsCertifierDetails'
                label={FtaTranslatedTextsObject.sameAsCertifierFL}
                key={'exporter_sameAsCertifierDetailsKey'}
                checked={exporterChecked}
                onChange={exporterSameDetailsOnTrigger}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }} >
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name={`exporterName`}
              type='text'
              label={FtaTranslatedTextsObject.entityNameFL}
              key={'exporterKey_' + exporterKey + 'Name'}
              required
              disabled={exporterIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='exporterJobTitle'
              label={FtaTranslatedTextsObject.jobTitleFL}
              key={'exporterKey_' + exporterKey + 'Title'}
              disabled={exporterIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='exporterEmailAddress'
              label={FtaTranslatedTextsObject.emailAddressFL}
              key={'exporterKey_' + exporterKey + 'Email'}
              disabled={exporterIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='exporterContactNo'
              label={FtaTranslatedTextsObject.contactNoFL}
              key={'exporterKey_' + exporterKey + 'Contact'}
              disabled={exporterIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <CngTextField
              name='exporterAddress'
              label={FtaTranslatedTextsObject.addressFL}
              key={'exporterKey_' + exporterKey + 'Address'}
              disabled={exporterIsDisabled}
            />
          </CngGridItem>
        </Grid>

        <hr />

        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }}>
          <Grid item sm={12} style={{ display: 'flex' }}>
            <Grid xs={7}>
              <Typography variant='h5' className='font-bolder'>
                {FtaTranslatedTextsObject.producerInformation}
                <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.producerIfDifferentFL}</span>
              </Typography>

              <Box fontSize={10} paddingBottom={2}>
                <span style={{ color: 'red' }}>*</span>
                {'Mandatory fields'}
              </Box>
            </Grid>

            <Grid xs={5} style={{ display: 'contents', textAlign: 'right', paddingRight: '0px' }}>
              <span> {FtaTranslatedTextsObject.producerConfidentialityFL}</span>
              <CngSwitchField
                name='isProducerConfidentiality'
                label=''
                key='producerConfidentialityKey'
                checked={isConfidentiality}
                onChange={e => handleConfidentialityChange(e)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ margin: '0px', paddingRight: '10px', display: 'contents', textAlign: 'right' }} >
            <CngGridItem xs={12} sm={6}>
            </CngGridItem>
            <CngGridItem xs={12} sm={3} style={{ direction: 'rtl' }}>
              <CngCheckboxField
                name='producerVariousImporters'
                key='producerVariousImportersKey'
                label={FtaTranslatedTextsObject.producerDeclareVariousFL}
                disabled={confidentialityDisabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} >
              <Typography display='inline'
                style={{ marginRight: '1em' }}>
                Same as
              </Typography>

              <Select
                disabled={producerIsDisabled}
                disableUnderline={true}
                value={producerSameAs}
                onChange={(event) => {
                  let value = event.target.value
                  setProducerSameAs(value)
                }}

              >
                <MenuItem value='CERTIFIER'>certifier details</MenuItem>
                <MenuItem value='EXPORTER'>exporter details</MenuItem>
              </Select>

              <CngCheckboxField
                name='producerSameAsCheckBox'
                label={''}
                key='producerSameAsKey'
                disabled={confidentialityDisabled}
                checked={producerSameAsChecked}
                onChange={producerSameDetailsOnTrigger}
              />

            </CngGridItem>
          </Grid>
        </Grid>



        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }} >
          <CngGridItem xs={12} sm={3}>
            {producerIsDisabled ?
              <CngTextField
                name='producerName'
                label={FtaTranslatedTextsObject.entityNameFL}
                key={'producerKey_' + producerKey + 'Name'}
                disabled={producerIsDisabled}
              />
              :
              <CngTextField
                name='producerName'
                label={FtaTranslatedTextsObject.entityNameFL}
                isRequired
                key={'producerKey_' + producerKey + 'Name'}
                disabled={producerIsDisabled}
              />
            }

          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='producerJobTitle'
              label={FtaTranslatedTextsObject.jobTitleFL}
              key={'producerKey_' + producerKey + 'Title'}
              disabled={producerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='producerEmailAddress'
              label={FtaTranslatedTextsObject.emailAddressFL}
              key={'producerKey_' + producerKey + 'Email'}
              disabled={producerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='producerContactNo'
              label={FtaTranslatedTextsObject.contactNoFL}
              key={'producerKey_' + producerKey + 'Contact'}
              disabled={producerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <CngTextField
              name='producerAddress'
              label={FtaTranslatedTextsObject.addressFL}
              key={'producerKey_' + producerKey + 'Address'}
              disabled={producerIsDisabled}
            />
          </CngGridItem>
        </Grid>

        <hr />
        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }}>
          <Grid item sm={12} style={{ display: 'flex' }}>
            <Grid xs={3}>
              <Typography variant='h5' className='font-bolder'>
                {FtaTranslatedTextsObject.importerInformation}
                <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.producerIfDifferentFL}</span>
              </Typography>

              <Box fontSize={10} paddingBottom={2}>
                <span style={{ color: 'red' }}>*</span>
                {'Mandatory fields'}
              </Box>
            </Grid>

            <Grid xs={9} style={{ textAlign: 'right', paddingRight: '20px', display: 'contents', direction: 'rtl' }}>
              <CngGridItem xs={12} sm={3}>
                <CngCheckboxField
                  name='importerUnknownImporter'
                  label={FtaTranslatedTextsObject.importerDeclareUnknownFL}
                  key='importerUnknownImporterKey'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={3}>
                <CngCheckboxField
                  name='importerVariousImporters'
                  label={FtaTranslatedTextsObject.importerDeclareVariousFL}
                  key='importerVariousImportersKey'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={3}>
                <CngCheckboxField
                  name='importerSameAsCertifier'
                  label={FtaTranslatedTextsObject.sameAsCertifierFL}
                  key='importer_sameAsCertifierDetailsKey'
                  checked={importerChecked}
                  onChange={(e) => {
                    importerSameDetailsOnTrigger(e)
                  }}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ margin: '0px', padding: '10px' }} >
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='importerName'
              label={FtaTranslatedTextsObject.entityNameFL}
              key={'importer_' + importerKey + 'Name'}
              required
              disabled={importerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='importerJobTitle'
              label={FtaTranslatedTextsObject.jobTitleFL}
              key={'importer_' + importerKey + 'Title'}
              disabled={importerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='importerEmailAddress'
              label={FtaTranslatedTextsObject.emailAddressFL}
              key={'importer_' + importerKey + 'Email'}
              disabled={importerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='importerContactNo'
              label={FtaTranslatedTextsObject.contactNoFL}
              key={'importer_' + importerKey + 'Contact'}
              disabled={importerIsDisabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <CngTextField
              name='importerAddress'
              label={FtaTranslatedTextsObject.addressFL}
              key={'importer_' + importerKey + 'Address'}
              disabled={importerIsDisabled}
            />
          </CngGridItem>
        </Grid>
      </Card >
    </>
  )
}

export default EntityInformationDetails