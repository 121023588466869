import React from 'react'
import { Grid } from '@mui/material'
import { Card, CardContent } from '@material-ui/core'
import StepperForm from '../add/StepperForm'



function AddPage({ showNotification }) {
      return (<>
            <Card>
                  <CardContent>
                        <Grid container>
                              <StepperForm
                                    showNotification={showNotification}
                                    actionType={'add'}
                              />
                        </Grid>
                  </CardContent>
            </Card>
      </>
      )
}

export default AddPage