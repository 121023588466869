export const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-tmp`

const CALISTA_TRADEMGMT_FTA_CALCULATOR = `${MODULE_BASE}/ftacalculator`
const CALISTA_TRADEMGMT_MY_PRODUCT = `${MODULE_BASE}/myproduct`
const CALISTA_TRADEMGMT_PRODUCT_DETAILS = `${MODULE_BASE}/ftacalculator/productdetails`
const CALISTA_TRADEMGMT_VIEW_FTA_PRODUCT_DETAILS = `${MODULE_BASE}/ftacalculator/productdetails-viewfta`
const CALISTA_TRADEMGMT_FTA_ACTIVITY_HISTORY = `${MODULE_BASE}/ftaactivityhistory`
const CALISTA_TRADEMGMT_DPS = `${MODULE_BASE}/deniedparty`
const CALISTA_TRADEMGMT_SELFCERTIFICATE = `${MODULE_BASE}/self-certification`
const CALISTA_TRADEMGMT_SELFCERTIFICATE_HISTORY = `${MODULE_BASE}/self-certification-history`

const pathMap = {
  TRADE_MGMT_FTA: CALISTA_TRADEMGMT_FTA_CALCULATOR,
  MY_PRODUCT_FTA: CALISTA_TRADEMGMT_MY_PRODUCT,
  TRADE_MGMT_PRODUCT_DETAILS: CALISTA_TRADEMGMT_PRODUCT_DETAILS,
  TRADE_MGMT_VIEW_FTA_PRODUCT_DETAILS:
    CALISTA_TRADEMGMT_VIEW_FTA_PRODUCT_DETAILS,
  TRADE_MGMT_FTA_ACTIVITY_HISTORY: CALISTA_TRADEMGMT_FTA_ACTIVITY_HISTORY,

  //Denied Party
  DPS_SEARCH_PAGE: CALISTA_TRADEMGMT_DPS,

  SELF_CERTIFICATE: CALISTA_TRADEMGMT_SELFCERTIFICATE,
  SELF_CERTIFICATE_GENERATE: `${CALISTA_TRADEMGMT_SELFCERTIFICATE}/generate`,
  SELF_CERTIFICATE_HISTORY: CALISTA_TRADEMGMT_SELFCERTIFICATE_HISTORY,
  SELF_CERTIFICATE_HISTORY_VIEW: `${CALISTA_TRADEMGMT_SELFCERTIFICATE_HISTORY}/:id`
}

export default pathMap
