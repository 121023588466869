import React from 'react'
import { makeStyles } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useSelector } from 'react-redux'

import Utils from 'src/shared/Utils'
import _ from 'lodash'

import SearchFormProperties from './SearchFormProperties'



const {
  form: {
    CngForm
  }
} = components

const {
  FormState
} = constants



const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit'
  }
}))

function FTASelfCertificateSearchForm({ showNotification }) {
  const {
    filters
  } = useSelector(({ filters }) => ({ filters }))


  let formData = {
    materialSkuNo: '',
    hsCode: '',
    hsDescription: '',
    principal: '',
    fta: ''
  }


  if (filters != null && filters.length > 0) {
    if (_.find(filters, { fieldName: 'materialSkuNo' })) {
      formData.materialSkuNo = _.find(filters, { fieldName: 'materialSkuNo' }).value1
    }
    if (_.find(filters, { fieldName: 'hsCode' })) {
      formData.hsCode = _.find(filters, { fieldName: 'hsCode' }).value1
    }
    if (_.find(filters, { fieldName: 'hsDescription' })) {
      formData.hsDescription = _.find(filters, { fieldName: 'hsDescription' }).value1
    }
    if (_.find(filters, { fieldName: 'principal' })) {
      formData.principal = _.find(filters, { fieldName: 'principal' }).value1
    }
    if (_.find(filters, { fieldName: 'fta' })) {
      formData.fta = _.find(filters, { fieldName: 'fta' }).value1
    }
  }


  return (
    <CngForm
      formState={FormState.COMPLETED}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <SearchFormProperties.FormFields
        disabled={false}
        showNotification={showNotification}
      />}
      hookFormProps={{
        initialValues: formData,
        mode: 'all'
      }}
      renderButtonSection={() => undefined}
    />


  )
}

export default FTASelfCertificateSearchForm
