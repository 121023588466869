import React from 'react'
import { Card, Box, Typography, makeStyles } from '@material-ui/core'
import { Grid } from '@mui/material'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import TmpSelfCertificateTranslationText from '../../../shared/TmpSelfCertificateTranslationText'


EntityInformationViewDetails.propTypes = {}

const useStyles = makeStyles((theme) => ({
  titleSpanSection: {
    color: '#8996AF',
    fontSize: '12px',
    fontWeight: 'normal'
  }
}))




function EntityInformationViewDetails(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  const classes = useStyles()
  const { certifierInformation,
    exporterInformation, producerInformation, importerInformation } = props

  return (
    <>
      <Card style={{ padding: '0px' }}>

        {/* <Grid container spacing={2} style={{ backgroundColor: '#EDEDF2', padding: '15px' }}> */}
        <Grid container spacing={2} style={{ backgroundColor: '#EDEDF2', padding: '15px', wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.certifierInformation}
            </Typography>
          </Grid>


          <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
            <Grid item xs={12} sm={2}>
              <AccountBoxIcon fontSize='small' />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Box fontSize={15} className='font-bolder'>
                {certifierInformation?.entityName !== undefined ? certifierInformation.entityName : ''}
              </Box>
              <Box fontSize={10} >
                {FtaTranslatedTextsObject.jobTitleFL}: <span className={classes.titleSpanSection}>{certifierInformation?.jobTitle !== undefined ? certifierInformation.jobTitle : ''}</span>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
            <Grid item xs={12} sm={2}>
              <LocationOnIcon fontSize='small' />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant='h7'>
                {certifierInformation?.address !== undefined ? certifierInformation.address : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
            <Grid item xs={12} sm={2}>
              <EmailIcon fontSize='small' />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant='h7'>
                {certifierInformation?.emailAddress !== undefined ? certifierInformation.emailAddress : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
            <Grid item xs={12} sm={2}>
              <PhoneIcon fontSize='small' />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant='h7'>
                {certifierInformation?.contactNo !== undefined ? certifierInformation.contactNo : ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ padding: '15px', wordWrap: 'break-word' }}>
          <Grid item xs={6} sm={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.exporterInformation}
              <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.exporterIfDifferentFL}</span>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} style={{ textAlign: 'end' }} >
            <span fontSize='10'>Same as certifier details</span>
            <CheckCircleIcon fontSize='small' style={{
              fill:
                exporterInformation?.exporterSameAsCertifier === true ? '#5E81F4' : '#EDEDF2'
            }
            } />
          </Grid>



          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.entityNameFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {exporterInformation?.entityName !== undefined ? exporterInformation.entityName : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.jobTitleFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {exporterInformation?.jobTitle !== undefined ? exporterInformation.jobTitle : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.emailAddressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {exporterInformation?.emailAddress !== undefined ? exporterInformation.emailAddress : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.contactNoFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {exporterInformation?.contactNo !== undefined ? exporterInformation.contactNo : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.addressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {exporterInformation?.address !== undefined ? exporterInformation.address : ''}
            </Box>
            <hr />
          </Grid>


          <Grid item xs={6} sm={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.producerInformation}
              <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.producerIfDifferentFL}</span>
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3} style={{ textAlign: 'end' }}>
            {FtaTranslatedTextsObject.producerDeclareVariousFL}
            <CheckCircleIcon color='primary' fontSize='small' style={{
              fill:
                producerInformation?.producerVariousImporters === true ? '#5E81F4' : '#EDEDF2'
            }} />
          </Grid>

          <Grid item xs={6} sm={3} style={{ textAlign: 'end' }}>
            Same as {producerInformation?.producerSameAs !== undefined ? producerInformation?.producerSameAs : 'CERTIFIER'} details
            <CheckCircleIcon color='primary' fontSize='small' style={{
              fill:
                producerInformation?.producerSameAsCheckBox === true ? '#5E81F4' : '#EDEDF2'
            }} />
          </Grid>




          <Grid item xs={12} sm={3}>

            <Box fontSize={10}>
              {FtaTranslatedTextsObject.entityNameFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {producerInformation?.entityName !== undefined ? producerInformation.entityName : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.jobTitleFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {producerInformation?.jobTitle !== undefined ? producerInformation.jobTitle : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.emailAddressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {producerInformation?.emailAddress !== undefined ? producerInformation.emailAddress : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.contactNoFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {producerInformation?.contactNo !== undefined ? producerInformation.contactNo : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.addressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {producerInformation?.address !== undefined ? producerInformation.address : ''}
            </Box>
            <hr />
          </Grid>



          <Grid item xs={6} sm={3}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.importerInformation}
              <span className={classes.titleSpanSection}>{FtaTranslatedTextsObject.producerIfDifferentFL}</span>
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3} style={{ textAlign: 'end' }}>
            {FtaTranslatedTextsObject.importerDeclareUnknownFL}
            <CheckCircleIcon color='primary' fontSize='small' style={{
              fill:
                importerInformation?.unknownImporter === true ? '#5E81F4' : '#EDEDF2'
            }}
            />
          </Grid>

          <Grid item xs={6} sm={3} style={{ textAlign: 'end' }}>
            {FtaTranslatedTextsObject.importerDeclareVariousFL}
            <CheckCircleIcon color='primary' fontSize='small' style={{
              fill:
                importerInformation?.variousImporter === true ? '#5E81F4' : '#EDEDF2'
            }}
            />
          </Grid>
          <Grid item xs={6} sm={3} style={{ textAlign: 'end' }}>
            Same as certifier details
            <CheckCircleIcon color='primary' fontSize='small' style={{
              fill:
                importerInformation?.importerSameAs === true ? '#5E81F4' : '#EDEDF2'
            }}
            />
          </Grid>




          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.entityNameFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {importerInformation?.entityName !== undefined ? importerInformation.entityName : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.jobTitleFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {importerInformation?.jobTitle !== undefined ? importerInformation.jobTitle : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.emailAddressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {importerInformation?.emailAddress !== undefined ? importerInformation.emailAddress : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.contactNoFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {importerInformation?.contactNo !== undefined ? importerInformation.contactNo : ''}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.addressFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {importerInformation?.address !== undefined ? importerInformation.address : ''}
            </Box>
          </Grid>
        </Grid>
      </Card >
    </>
  )

}


export default EntityInformationViewDetails