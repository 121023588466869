import React from 'react'
import { Grid } from '@mui/material'

import CertificateOfOriginViewComponent from './view/CertificateOfOriginViewComponent'
import EntityInformationViewDetails from './view/EntityInformationViewDetails'
import CertificateItemsDetails from './CertificateItemsDetails'
import BlanketPeriodViewComponent from './view/BlanketPeriodViewComponent'
import SignatureViewComponent from './view/SignatureViewComponent'


ViewDetailedPanel.propTypes = {}


function ViewDetailedPanel(props) {
  const { viewPanelSelfCertificate, signatureImage } = props

  return (
    <>
      <Grid id={'pdfPrint'} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <CertificateOfOriginViewComponent
            certificateOfOrigin={viewPanelSelfCertificate.certificateOfOrigin}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
          <EntityInformationViewDetails
            certifierInformation={viewPanelSelfCertificate.certifierInformation}
            exporterInformation={viewPanelSelfCertificate.exporterInformation}
            producerInformation={viewPanelSelfCertificate.producerInformation}
            importerInformation={viewPanelSelfCertificate.importerInformation}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
          <CertificateItemsDetails
            skuMaterialInfo={viewPanelSelfCertificate !== undefined ? viewPanelSelfCertificate?.skuAndMaterials?.skuMaterials : []}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
          <BlanketPeriodViewComponent
            blanketPeriod={viewPanelSelfCertificate.blanketPeriod}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
          <SignatureViewComponent
            signature={viewPanelSelfCertificate.signature}
            signatureImage={signatureImage}
          />
        </Grid>
      </Grid>
    </>
  )
}


export default ViewDetailedPanel