import React from 'react'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import ReplayIcon from '@material-ui/icons/Replay'
import SearchIcon from '@material-ui/icons/Search'

import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import Utils from 'src/shared/Utils'
import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import TradeManagementTranslationText from '../../../shared/TradeManagementTranslationText'



const {
  form: {
    field: { CngTextField, CngLookupAutocompleteField },
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants


const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit'
  }
}))

function FormFields({ ...props }) {

  const dispatch = useDispatch()

  const [materialSkuNo] = useField('materialSkuNo')
  const [hsCode] = useField('hsCode')
  const [hsDescription] = useField('hsDescription')
  const [principal] = useField('principal')
  const [fta] = useField('fta')

  const { reset } = useFormContext()




  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  const resetForm = () => {
    console.log('reset form')

    reset({
      materialSkuNo: '',
      hsCode: '',
      hsDescription: '',
      principal: '',
      fta: ''
    })
  }

  const submitForm = () => {
    let filterProperties = []
    if (materialSkuNo.value) {
      filterProperties.push({
        fieldName: 'materialSkuNo',
        operatorType: 'LIKE',
        value1: materialSkuNo.value
      })
    }
    if (hsCode.value) {
      filterProperties.push({
        fieldName: 'hsSubheading',
        operatorType: 'LIKE',
        value1: hsCode.value
      })
    }
    if (hsDescription.value) {
      filterProperties.push({
        fieldName: 'productDescription',
        operatorType: 'LIKE',
        value1: hsDescription.value
      })
    }
    if (principal.value) {
      filterProperties.push({
        fieldName: 'companyId',
        operatorType: 'EQUAL',
        value1: principal.value
      })
    }
    if (fta.value) {
      filterProperties.push({
        fieldName: 'fta',
        operatorType: 'EQUAL',
        value1: fta.value
      })
    }


    dispatch({ type: 'SET_FILTERS', payload: filterProperties })
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })


  }


  return (
    <>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={4}>
              <CngTextField
                size='small'
                name='materialSkuNo'
                label={FtaTranslatedTextsObject.materialNoSku}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <CngTextField
                size='small'
                name='hsCode'
                label={FtaTranslatedTextsObject.hsCode}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <CngTextField
                size='small'
                name='hsDescription'
                label={FtaTranslatedTextsObject.hsDescription}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <CngLookupAutocompleteField
                name="principal"
                label={FtaTranslatedTextsObject.principal}
                lookupProps={{
                  url: TmpCompanyProfileApiUrls.GET,
                  label: c => `${c.name}`,
                  value: 'id',
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <CngLookupAutocompleteField
                name="fta"
                label={FtaTranslatedTextsObject.fta}
                lookupProps={{
                  url: TmpCodeMasterApiUrls.GET,
                  label: c => `(${c.code}) - ` + ` ${c.name}`,
                  value: 'code',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'TMP_FTA'
                    }
                  ]
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <Grid container spacing={3}>
                <CngGridItem xs={12} sm={6}>
                  <Button variant='outlined' startIcon={<ReplayIcon />}
                    size='large'
                    onClick={() => resetForm()}
                    style={{
                      color: '#e91e63',
                      width: '100%',
                      textTransform: 'none'
                    }}>
                    Reset
                  </Button>
                </CngGridItem>

                <CngGridItem xs={12} sm={6}>
                  <Button variant='outlined' startIcon={<SearchIcon />}
                    onClick={submitForm}
                    color='primary' size='large'
                    style={{ width: '100%', textTransform: 'none', color: 'white', backgroundColor: 'blue' }}>
                    Search
                  </Button>
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>
    </>
  )
}


const DEFAULT_INITIAL_VALUES = {
  materialNoSku: '',
  hsCode: '',
  hsDescription: '',
  principal: '',
  fta: ''
}

const hookFormProps = {
  initialValues: JSON.parse(JSON.stringify(DEFAULT_INITIAL_VALUES))
}

const SearchFormProperties = Object.freeze({
  hookFormProps: hookFormProps,
  FormFields: FormFields
})

export default SearchFormProperties
