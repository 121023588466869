import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { components } from 'cng-web-lib'
import {
  IconButton
} from '@material-ui/core'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    }
  }
} = components


const ImageUpload = ({ label, imageSrcName, imageSrc, onImageChange, onDelete, initialHeight, initialWidth, initialDPI, ...props }) => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [maxHeight] = useState(initialHeight || 100);
  const [maxWidth] = useState(initialWidth || 100);
  const [maxDpi] = useState(initialDPI || 72);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setImage(imageSrc.value)
    setImageName(imageSrcName.value);
  }, [])

  const handleImageChange = (e) => {
    setErrorMessage('');

    const file = e.target.files[0];

    // Check if the file is an allowed image type
    if (!file.type.startsWith('image/')) {
      setErrorMessage('Invalid file type. Please upload a valid image.');
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        const fileName = fileInputRef.current.files[0].name;
        onImageChange(reader.result, fileName);


        img.onload = () => {
          // Check image dimensions and DPI
          if (img.width > maxWidth || img.height > maxHeight || img.width / img.naturalWidth * maxDpi > maxDpi || img.height / img.naturalHeight * maxDpi > maxDpi) {
            setErrorMessage(`Image dimensions must be within ${maxWidth}x${maxHeight} pixels and DPI within ${maxDpi}`);
          } else {
            setImage(reader.result);
            setImageName(fileName)
          }
        };
      };

      reader.readAsDataURL(file);
    }

  };
  const handleDelete = () => {
    setImage(null);
    setImageName(null);
    fileInputRef.current.value = '';
    onDelete();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = image;
    link.download = 'downloaded_image';
    link.click();
  };

  return (
    <div>
      <label htmlFor="imageInput">{label}</label><br />
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
      />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {image && (
        <div>
          <label>{imageName}</label><br />
          <img src={image} alt="Preview" style={{ maxWidth: '80%', maxHeight: '80%' }} />
          <IconButton onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
          &nbsp;
          <IconButton onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
