import React from 'react'
import { components } from 'cng-web-lib'

import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import FormProperties from 'src/views/tmp-admin/company-profile/FormProperties'


const {
  form: {
    CngEditForm,
    CngEditFormButtonSection
  },
  CngGridItem
} = components

function EditPageSelfCertDialog({ history, showNotification, onPostSubmitSuccess, principalId }) {

  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      shouldDisableEnterToSubmit
      showNotification={showNotification}
      history={history}
      bodySection={
        <FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          mode={"edit"}
          id={principalId}
        />
      }
      renderButtonSection={() => (
        <CngEditFormButtonSection>
          <CngGridItem>
          </CngGridItem>
        </CngEditFormButtonSection>
      )}
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: TmpCompanyProfileApiUrls.LIST
      }}
      update={{
        url: TmpCompanyProfileApiUrls.EDIT,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
      id={principalId}
      innerForm={true}
    />
  )

}


export default EditPageSelfCertDialog
