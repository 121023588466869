import React, { useEffect } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { components, useServices } from 'cng-web-lib'
import { createStore } from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import Utils from 'src/shared/Utils'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'

import FTASelfCertificateHistorySearchForm from '../table/form/FTASelfCertficateHistorySearchForm'
import { FTASelfCertificateHistoryTable } from '../table/FTASelfCertificateHistoryTable'



const {
  CngGridItem,
  table: { useDefaultNotification }
} = components



const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    padding: '20px'
  },
  sectionMain: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    padding: '20px'
  }
}))



const initialState = {
  pageSize: 10,
  pageNumber: 1,
  sortType: 'desc',
  sortColumn: 'createdDate',
  totalPages: 0,
  totalRecords: 0,
  selfCertificateHistory: [],
  tableKey: null,
  isLoading: false,
  hasError: false,
  filters: [],
  triggerFetch: true
}

const stateReducer = (state, { type, payload }) => {
  state = state ?? initialState;
  switch (type) {
    case 'SET_PAGE_SIZE':
      return {
        ...state, pageSize: payload
      }
    case 'SET_PAGE_NUMBER':
      return {
        ...state, pageNumber: payload
      }
    case 'SET_TOTAL_RECORDS':
      return {
        ...state, totalRecords: payload
      }
    case 'SET_TOTAL_PAGES':
      return {
        ...state, totalPages: payload
      }
    case 'SET_SORT_COLUMN':
      return {
        ...state, sortColumn: payload
      }
    case 'SET_SORT_TYPE':
      return {
        ...state, sortType: payload
      }
    case 'SET_SELF_CERTIFICATE_HISTORY':
      return {
        ...state, selfCertificateHistory: payload
      }
    case 'SET_FILTERS':
      return {
        ...state, filters: payload
      }
    case 'SET_LOADING':
      return {
        ...state, isLoading: payload
      }
    case 'SET_HAS_ERROR':
      return {
        ...state, hasError: payload
      }
    case 'SET_TRIGGER_FETCH':
      return {
        ...state, triggerFetch: payload
      }
    case 'SET_RESET':
      return initialState
    default:
      return state
  }
}

const store = createStore(stateReducer)

function FtaSelfCertificateHistoryPage(props) {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { securedSendRequest } = useServices()




  const {
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch
  } = useSelector(({
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    filters,
    triggerFetch
  }) => ({
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch
  }))

  const {
    showNotification
  } = props




  const fetchSelfCertificateHistory = (formData) => { //change const name.

    dispatch({ type: 'SET_LOADING', payload: true })

    const sortProperties = sortColumn && sortType ? [{
      'sortField': sortColumn, 'sortType': sortType
    }] : []

    // Search payload
    const searchPayload = {
      'baseFilterDTO': {
        'filterType': 'AND',
        'filterProperties': filters,
        'sortProperties': sortProperties,
        'page': pageNumber - 1,
        'size': pageSize
      }
    }

    console.log('payload :: ', searchPayload)

    // API call
    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.SELF_CERTIFICATE_HISTORY_LIST,
      searchPayload,
      (response) => {
        const data = response?.data?.content || []
        const totalPages = response?.data?.totalPages || 0
        const totalCount = response?.data?.content?.length || 0
        dispatch({ type: 'SET_SELF_CERTIFICATE_HISTORY', payload: data })
        dispatch({ type: 'SET_TOTAL_PAGES', payload: totalPages })
        dispatch({ type: 'SET_TOTAL_RECORDS', payload: totalCount })
        dispatch({ type: 'SET_LOADING', payload: false })

      },
      (error) => {
        console.error(error)
      },
      // On complete
      () => {
        // Hide data loader overlay
        dispatch({ type: 'SET_LOADING', payload: false })
      },
      {}
    )

  }



  useEffect(() => {
    if (triggerFetch) {
      fetchSelfCertificateHistory()
      dispatch({ type: 'SET_TRIGGER_FETCH', payload: false })
    }
  }, [triggerFetch])


  return (


    <Grid container spacing={5}>
      <CngGridItem item xs={12}>
        <Box className={`cng-details-page__section ${classes.sectionBlueBg}`}>
          <FTASelfCertificateHistorySearchForm
            showNotification={showNotification}
          />
        </Box>
      </CngGridItem>

      <Grid item xs={12}>
        <FTASelfCertificateHistoryTable />

      </Grid>
    </Grid>
  )
}

FtaSelfCertificateHistoryPage.propTypes = {
  showNotification: PropTypes.func
}

export default (props) => (<Provider store={store}>
  <FtaSelfCertificateHistoryPage showNotification={props.showNotification} />
</Provider>);
