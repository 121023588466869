const TmpSupplierKeys = {

	//------------SUPPLIER------------------
	TMP_SUPPLIER: "tmpSupplier",
	TMP_SUPPLIER_TITLE: "tmpSupplierTitle",
	TMP_SUPPLIER_CREATE: "tmpSupplierCreate",
	TMP_SUPPLIER_EDIT: "tmpSupplierEdit",
	TMP_SUPPLIER_VIEW: "tmpSupplierView",
	TMP_SUPPLIER_NAME: "tmpSupplierName",
	TMP_SUPPLIER_ADDRESS: "tmpSupplierAddress",
	TMP_SUPPLIER_CONTACT_NAME: "tmpSupplierContactName",
	TMP_SUPPLIER_CONTACT_EMAIL: "tmpSupplierContactEmail",
	TMP_SUPPLIER_CONTACT_PHONE: "tmpSupplierContactPhoneNumber"

}

export default TmpSupplierKeys