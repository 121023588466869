import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { components } from 'cng-web-lib'

import { useFormContext } from 'react-hook-form'
import DiscardDialog from './DiscardDialog'
import { useHistory } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_TradeManagement'



StepperButtonSection.propTypes = {}
const {
  CngGridItem
} = components


function StepperButtonSection(props) {

  const { activeStep, steps, handleNext, handleBack, actionType, downloadPDFOnClick, showErrorNotification, signatureImage, formState } = props
  const [isDiscardDialogOpened, setDiscardDialogOpened] = useState(false)
  const { trigger } = useFormContext()
  const history = useHistory()

  const openDiscardDialog = () => {
    setDiscardDialogOpened(true)
  }
  const closeDiscardDialog = () => {
    setDiscardDialogOpened(false)
  }





  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={6} sm={6}>
        {(activeStep !== 2) &&
          <Button
            variant='contained'
            className='ng-button-filled-secondary2 originalText'
            type='button'
            size='large'
            onClick={() => openDiscardDialog()}
            sx={{ borderRadius: '8px !important' }}>
            Discard</Button>
        }
        {(activeStep === 2) &&
          <Button
            variant='contained'
            className='ng-button-filled-secondary2 originalText'
            type='button'
            size='large'
            onClick={() =>
              history.push(pathMap.SELF_CERTIFICATE_HISTORY)
            }
            sx={{ borderRadius: '8px !important' }}>
            View FTA self-certification history</Button>
        }
      </Grid>

      <CngGridItem item xs={6} sm={6}>
        <Box display='flex' justifyContent='flex-end'>
          {(activeStep !== 0 && activeStep !== steps.length - 1) &&
            <Button
              variant='contained'
              className='button-blue originalText'
              onClick={handleBack}
              type='button'
              size='large'
              style={{ borderRadius: '8px' }}
            >Back</Button>
          }
          {activeStep === 0 &&
            <Button
              variant='contained'
              onClick={async (event) => {
                const isValid = await trigger()
                if (isValid) {
                 if (signatureImage === '' || signatureImage === null) {
                        showErrorNotification('Signature is required.')
                        console.log(formState.errors)
                  }
                  else{
                   handleNext()
                  }
                }
                else{
                    if (signatureImage === '' || signatureImage === null) {
                       showErrorNotification('Signature is required.')
                    }
                    console.log(formState.errors)
                }
              }}
              type='button'
              size='large'
              className='button-light-blue originalText'
              style={{ borderRadius: '8px', marginLeft: '5px' }}
            >Confirm & Review</Button>
          }
          {activeStep === 1 &&
            <Button
              variant='contained'
              type='submit'
              onClick={async (event) => {
                const isValid = await trigger()
                if (isValid) {
                  //this should trigger.
                  //by right dont need isValid here?
                  console.log('pass')
                } else {
                  console.log(formState.errors)
                }
              }}
              // type='button'
              size='large'
              className='button-light-blue originalText'
              style={{ borderRadius: '8px', marginLeft: '5px' }}
            >Generate Certificate</Button>
          }
          {activeStep === 2 &&
            <Button
              variant='contained'
              className='button-light-blue originalText'
              type='button'
              size='large'
              style={{ borderRadius: '8px', marginLeft: '5px' }}
              onClick={async (event) => {
                const isValid = await trigger()
                if (isValid) {
                  downloadPDFOnClick()
                }
              }}
            >{actionType === 'add' ? 'Download PDF' : 'null'}
            </Button>
          }
        </Box>
      </CngGridItem>

      <DiscardDialog isDialogOpen={isDiscardDialogOpened} closeDialog={closeDiscardDialog} />

    </Grid>
  )

}

export default StepperButtonSection