import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import TranslationText from './TranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = ({
  name: "",
  supplierAddress: "",
  contactName: "",
  contactEmail: "",
  contactPhoneNumber: ""

})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const translatedTextsObject = TranslationText();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="name"
                  label={translatedTextsObject.name}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="supplierAddress"
                  label={translatedTextsObject.supplierAddress}
                  disabled={disabled}
                  multiline={true}
                />
              </CngGridItem>
            </Grid>

            <h5 style={{ fontWeight: 'bold', marginTop: '21px' }}>Person of Contact</h5>
            <hr />
            <Grid container spacing={6}>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="contactName"
                  label={translatedTextsObject.contactName}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="contactEmail"
                  label={translatedTextsObject.contactEmail}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <CngTextField
                  name="contactPhoneNumber"
                  label={translatedTextsObject.contactPhoneNumber}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
