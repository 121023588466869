import {
  BasePage,
  FtaProductDetailsPage,
  ViewFtaProductDetailsPage,
  FtaActivityHistoryPage
} from 'src/views/trademanagement/ftacalculator'
import { MyProductsPage, ProductPage } from 'src/views/trademanagement/myproducts'
import { FtaSelfCertificatePage, SelfCertificateAddPage, FtaSelfCertificateHistoryPage, FtaSelfCertificateHistoryViewPage } from 'src/views/trademanagement/ftaselfcertificate'
import pathMap from 'src/paths/PathMap_TradeManagement'

class FTACalculatorRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TRADE_MGMT_FTA,
        component: BasePage
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_PRODUCT_DETAILS,
        component: FtaProductDetailsPage
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_VIEW_FTA_PRODUCT_DETAILS,
        component: ViewFtaProductDetailsPage
      },
      {
        exact: true,
        path: pathMap.MY_PRODUCT_FTA,
        component: MyProductsPage,
      },
      {
        exact: true,
        path: `${pathMap.MY_PRODUCT_FTA}/new`,
        component: ProductPage,
      },
      {
        exact: true,
        path: `${pathMap.MY_PRODUCT_FTA}/:productId`,
        component: ProductPage,
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_FTA_ACTIVITY_HISTORY,
        component: FtaActivityHistoryPage
      },
      {
        exact: true,
        path: pathMap.SELF_CERTIFICATE,
        component: FtaSelfCertificatePage
      },
      {
        exact: true,
        path: pathMap.SELF_CERTIFICATE_GENERATE,
        component: SelfCertificateAddPage
      },
      {
        exact: true,
        path: pathMap.SELF_CERTIFICATE_HISTORY,
        component: FtaSelfCertificateHistoryPage
      },
      {
        exact: true,
        path: pathMap.SELF_CERTIFICATE_HISTORY_VIEW,
        component: FtaSelfCertificateHistoryViewPage
      }
    ]
  }
}

export default FTACalculatorRoute
