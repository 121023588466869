import React, { forwardRef, useEffect, useRef } from 'react'
import { Box, Button, makeStyles, Typography, Chip } from '@material-ui/core'

import MaterialTable, { MTableToolbar } from 'material-table'

import TablePagination from './TablePagination'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded'
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import VisibilityIcon from '@material-ui/icons/Visibility';
import Utils from 'src/views/common/utils/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'


FTASelfCertificateHistoryTable.propTypes = {}




const tableIcons = {
  // * https://www.freakyjolly.com/react-material-table-how-to-show-icons-in-action-and-other-components/
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropDownIcon {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <SkipPreviousRoundedIcon {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SkipNextRoundedIcon {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <FontAwesomeIcon icon='fa-table-columns' style={{ marginLeft: '1em' }} />)
}


export const useFTASelfCertificateStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& tbody td': {
      fontSize: '.75rem',
      color: theme.palette.text.gray900,
      '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      },
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
    }
  },
  limitedText: {
    maxWidth: '16em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  action: {
    color: theme.palette.text.textSecondary,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36, width: 36,
    fontSize: '.875rem'
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '.875rem'
  },
  primaryText: {
    fontSize: '.875rem'
  },
  secondaryText: {
    fontSize: '.75rem',
    color: theme.palette.text.textSecondary
  },
  colorBlue: {
    color: theme.palette.primary.main
  },
  colorGreen: {
    color: theme.palette.success.main
  },
  colorOrange: {
    color: '#C48E1F'
  }

}))



function FTASelfCertificateHistoryTable(props) {

  const history = useHistory()

  const classes = useFTASelfCertificateStyles()
  const tableRef = useRef()



  const {
    selfCertificateHistory,
    isLoading,
    sortColumn,
    sortType
  } = useSelector(({
    selfCertificateHistory,
    isLoading,
    sortColumn,
    sortType
  }) => ({
    selfCertificateHistory,
    isLoading,
    sortColumn,
    sortType
  }))

  const dispatch = useDispatch()



  const handleOnViewClick = (rowData) => {
    history.push(`/cal-tmp/self-certification-history/${rowData.id}`, {
      detailsData: {
        certificateHistoryId: rowData.id
      }
    })
  }


  const getColumns = () => {
    const columns = [
      {
        title: 'Certificate Id', field: 'generatedCertificateUuid', width: "30%", render: rowData =>
          <Box>
            <Typography
              className={clsx(classes.primaryText)}>{rowData.generatedCertificateUuid}</Typography>
          </Box>
      },
      {
        title: 'Sku Count', field: 'skuCount', render: rowData =>
          <Box>
            <Chip
              label={rowData.skuCount + ' SKUs'}
              size='medium'
              className={classes.primaryText}
            />
          </Box>
      },
      {
        title: 'Principal', field: 'principal', render: rowData =>
          <Box>
            <Typography
              className={clsx(classes.primaryText)}>{rowData.principal}</Typography>
          </Box>
      },
      {
        title: 'FTA', field: 'fta', render: rowData =>
          <Typography
            className={clsx(classes.primaryText)}>{rowData.fta}</Typography>
      },
      {
        title: 'Certifier', field: 'certifier', align: 'center', render: rowData =>
          <Typography
            className={clsx(classes.primaryText)}>{rowData.certifier} </Typography>
      },
      {
        title: 'Certified on', field: 'createdDate',
        defaultSort: sortType,
        render: rowData => Utils.formatDate(rowData.createdDate, 'DD MMM YYYY , HH:mm', ' ')
      },
      {
        title: 'Action', field: 'last_name',
        sorting: false,
        render: rowData => (
          <Button variant='outlined' startIcon={<VisibilityIcon />}
            color='primary'
            style={{ textTransform: 'none' }}
            onClick={() => {
              handleOnViewClick(rowData)
            }}>
          </Button>
        )
      }
    ]

    const clonedMap = columns.map(column => {
      if (sortColumn && sortType && sortColumn === column.field) {
        let clone = { ...column, defaultSort: sortType }
        return clone
      } else {
        return column
      }

    })

    return clonedMap
  }

  const onOrderChange = (sourceIndex, destinationIndex) => {
    if (sourceIndex !== -1 && destinationIndex != null) {
      const sortColumn = tableRef.current.dataManager.columns[sourceIndex].field
      dispatch({ type: 'SET_SORT_COLUMN', payload: sortColumn })
      dispatch({ type: 'SET_SORT_TYPE', payload: destinationIndex })
    } else {
      dispatch({ type: 'SET_SORT_COLUMN', payload: null })
      dispatch({ type: 'SET_SORT_TYPE', payload: null })
    }
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }

  const onRowClick = (event, rowData) => {
    //Remain the same behaviour as Self certification table.
    console.log('on row click ')
  }

  let topToolbar = props => (
    <div>
      <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
        <div style={{ flex: '1 1 10%' }} />
        <MTableToolbar {...props} />
      </div>
    </div>
  )


  let bottomPaginationBar = (props) => (
    <>
      <Box mt={2}>
        <TablePagination
          {...props}
        />
      </Box>
    </>
  )

  useEffect(() => {
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }, [])

  return (
    <Box className={classes.table}>
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        columns={getColumns()}
        isLoading={isLoading}
        onOrderChange={onOrderChange}
        onRowClick={onRowClick}
        localization={{
          toolbar: { addRemoveColumns: 'View Columns' }
        }}
        options={{
          selection: false,
          search: false,
          paginationType: 'stepped',
          columnsButton: true,
          pageSize: 100,
          loadingType: 'overlay',
          emptyRowsWhenPaging: false,
          padding: 'default',
          showTitle: false,
          showSelectAllCheckbox: false
        }}
        data={
          selfCertificateHistory
        }
        components={{
          Toolbar: topToolbar,
          Pagination: bottomPaginationBar
        }}
      />
    </Box>
  )
}

export { FTASelfCertificateHistoryTable }
