import React, { useState, createContext, useEffect, useMemo } from 'react'
import { Box, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { components, useServices } from 'cng-web-lib'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import EditPageSelfCertDialog from 'src/views/tmp-admin/company-profile/EditPageSelfCertDialog'
import { useHistory } from 'react-router-dom'
import TradeMgmtApiUrls from '../../../../apiUrls/TradeMgmtApiUrls'


const {
  button:
  {
    CngPrimaryButton
  },
  CngDialog
} = components
const DialogContext = createContext(null)

function EditPrincipalProfileDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  principalProfileId,
  setSignatureImage,
  setIsCaptureNewSignature
}) {
  const [isSubmitting] = useState(false)
  const [isSubmitSuccess, setSubmitSuccess] = useState(false)
  const history = useHistory()
  const { securedSendRequest } = useServices()



  useEffect(() => {
    if (isSubmitSuccess) {
      setIsCaptureNewSignature(false)
      const payloadReq = {
        "principalId": principalProfileId
      }
      const onSuccess = (response) => {
        console.log("success")
        if (response.data?.status === 200) {
          console.log("success")
          setSignatureImage(response.data.results.base64)
        }
      }

      const onError = (error) => {
        console.log(error)
      }

      const onComplete = () => {
        console.log('Complete.')
      }

      securedSendRequest.execute(
        'POST',
        TradeMgmtApiUrls.GET_SIGNATURE_FOR_CERTIFICATE,
        payloadReq,
        onSuccess,
        onError,
        onComplete
      )
    }
  }, [isSubmitSuccess])

  const providerValue = useMemo(() => ({ closeDialog }), []);

  return (
    <DialogContext.Provider
      value={providerValue}
    >
      <CngDialog
        customDialogContent={
          <Box mx={2} my={1}><Grid container spacing={2} alignItems="center"><Grid item xs>
            <div style={isSubmitSuccess ? { display: 'none' } : {}}>
              <EditPageSelfCertDialog
                showNotification={showNotification}
                history={history}
                principalId={principalProfileId}
                onPostSubmitSuccess={() => {
                  setSubmitSuccess(true)
                }}
              /></div>

            <div style={isSubmitSuccess ? {} : { display: 'none' }}>
              <Alert severity="success">
                Success: Principal profile has been Updated.
              </Alert>

              <div>
                <br />
                <CngPrimaryButton color="secondary"
                  onClick={() => {
                    closeDialog(false)
                    setSubmitSuccess(false)
                  }}
                  disabled={isSubmitting}  >
                  Close
                </CngPrimaryButton>
              </div>
            </div>
          </Grid></Grid></Box>
        }
        dialogTitle={<>
          <b>Edit Principal Profile</b>
          <CloseDialogIconButton
            onClick={() => {
              closeDialog(false)
            }}
            disabled={isSubmitting}
          />
        </>}

        open={isDialogOpen}
        fullWidth
        maxWidth='lg'

      />
    </DialogContext.Provider>
  )
}

export default EditPrincipalProfileDialog
