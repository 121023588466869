import Namespace from 'src/constants/locale/Namespace'
import TmpSelfCertificateKeys from 'src/constants/locale/key/TmpSelfCertificate'
import { useTranslation } from 'cng-web-lib'



const TmpSelfCertificateTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TMP_SELF_CERT
  ])

  let moduleTitle = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SELFCERTIFICATETITLE
  )

  let selfCertHistoryModuleTitle = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SELFCERTIFICATEHISTORYTITLE
  )

  let selfCertHistoryViewTitle = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SELFCERTIFICATEHISTORYVIEWTITLE
  )

  let selfCertifyTitle = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SELFCERTIFYTITLE
  )

  /**
   * section titles
   */
  let selfCertifyProducts = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.SELF_CERTIFY_PRODUCTS
  )
  let certifierInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.CERTIFIER_INFORMATION
  )
  let exporterInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.EXPORTER_INFORMATION
  )
  let producerInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.PRODUCER_INFORMATION
  )
  let importerInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.IMPORTER_INFORMATION
  )
  let skuMaterialsInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.SKU_MATERIALS_INFORMATION
  )
  let blanketPeriodInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.BLANKET_PERIOD_INFORMATION
  )
  let signatureInformation = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.SectionTitles.SIGNATURE_INFORMATION
  )

  /**
   * Table Labels
   */
  let materialNoSkuTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.MATERIAL_NO_SKU
  )
  let hsCodeDescriptionTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.HS_CODE_DESCRIPTION
  )
  let principalTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.PRINCIPAL
  )
  let ftaTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.FTA
  )
  let rooQualificationTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.ROO_QUALIFICATIONS
  )
  let statusTL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.TableLabels.STATUS
  )

  /**
   * Buttons Labels
   */
  let discardBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.DISCARD
  )
  let backBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.BACK
  )
  let confirmReviewBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.CONFIRM_REVIEW
  )
  let generateCertificateBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.GENERATE_CERTIFICATE
  )
  let viewFtaSelfCertHistoryBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.VIEW_FTA_SELF_CERT_HISTORY
  )
  let downloadPdfBL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.DOWNLOAD_PDF
  )

  let backToSelfCertHistory = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.ButtonLabels.BACK_TO_SELF_CERT_HISTORY
  )

  /**
   * Field Labels
   */
  let materialNoFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.MATERIAL_NO_SKU
  )
  let hsCodeFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.HSCODE
  )
  let hsDescriptionFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.HSDESCRIPTON
  )
  let principalFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.PRINCIPAL
  )
  let ftaFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.FTA
  )
  let certificateIdFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CERTIFICATE_ID
  )
  let certificateOfOriginFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CERTIFICATE_OF_ORIGIN
  )
  let certifierTypeFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CERTIFIER_TYPE
  )
  let entityNameFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.ENTITY_NAME
  )
  let jobTitleFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.JOB_TITLE
  )

  let emailAddressFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.EMAIL_ADDRESS
  )
  let contactNoFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CONTACT_NO
  )
  let addressFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.ADDRESS
  )
  let exporterIfDifferentFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.EXPORTER_IF_DIFFERENT
  )
  let sameAsCertifierFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.SAME_AS_CERTIFIER_DETAILS
  )
  let producerIfDifferentFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.PRODUCER_IF_DIFFERENT
  )
  let producerConfidentialityFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.PRODUCER_CONFIDENTIALITY
  )
  let producerDeclareVariousFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.PRODUCER_DECLARE_VARIOUS
  )
  let importerDeclareUnknownFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.IMPORTER_DECLARE_UNKNOWN
  )
  let importerDeclareVariousFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.IMPORTER_DECLARE_VARIOUS
  )
  let productDescriptionFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.PRODUCT_DESCRIPTION
  )
  let baseUnitFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.BASE_UNIT
  )

  let fobPriceFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.FOB_PRICE
  )
  let blanketLabelFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.BLANKET_LABEL
  )
  let startDateFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.START_DATE
  )
  let endDateFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.END_DATE
  )
  let signatureLabelFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.SIGNATURE_LABEL
  )
  let captureNewSignatureFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CAPTURE_NEW_SIGNATURE
  )
  let signatureFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.SIGNATURE
  )
  let signerPrinterNameFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.SIGNER_PRINTED_NAME
  )
  let signerCertificationDateFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.SIGNER_CERTIFICATION_DATE
  )

  let certificationStartDateFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CERTIFICATION_START_DATE
  )

  let certificationEndDateFL = translate(
    Namespace.TMP_SELF_CERT,
    TmpSelfCertificateKeys.FieldLabels.CERTIFICATION_END_DATE
  )

  return {
    moduleTitle,
    selfCertifyProducts,
    certifierInformation,
    exporterInformation,
    producerInformation,
    importerInformation,
    skuMaterialsInformation,
    blanketPeriodInformation,
    signatureInformation,
    materialNoSkuTL,
    hsCodeDescriptionTL,
    principalTL,
    ftaTL,
    rooQualificationTL,
    statusTL,
    discardBL,
    backBL,
    confirmReviewBL,
    generateCertificateBL,
    viewFtaSelfCertHistoryBL,
    downloadPdfBL,
    materialNoFL,
    hsCodeFL,
    hsDescriptionFL,
    principalFL,
    ftaFL,
    certificateIdFL,
    certificateOfOriginFL,
    certifierTypeFL,
    entityNameFL,
    jobTitleFL,
    emailAddressFL,
    contactNoFL,
    addressFL,
    exporterIfDifferentFL,
    sameAsCertifierFL,
    producerIfDifferentFL,
    producerConfidentialityFL,
    producerDeclareVariousFL,
    importerDeclareUnknownFL,
    importerDeclareVariousFL,
    productDescriptionFL,
    baseUnitFL,
    fobPriceFL,
    blanketLabelFL,
    startDateFL,
    endDateFL,
    signatureLabelFL,
    captureNewSignatureFL,
    signatureFL,
    signerPrinterNameFL,
    signerCertificationDateFL,
    selfCertHistoryModuleTitle,
    backToSelfCertHistory,
    selfCertHistoryViewTitle,
    certificationStartDateFL,
    certificationEndDateFL,
    selfCertifyTitle
  }
}

export default TmpSelfCertificateTranslationText