import { Chip, Card, Grid, Typography } from '@material-ui/core'
import React from 'react'

import CertificateItemsTable from './CertificateItemsTable'
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'

function CertificateItemsDetails(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  const {
    skuMaterialInfo
  } = props

  return (
    <Card style={{ padding: '15px' }}>
      <Grid container spacing={1} sx={{ margin: '0px' }}>
        <Grid item sm={12}>
          <Grid xs={12}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.skuMaterialsInformation}
              <Chip
                label={
                  <Typography className={`font-mediumBold font-16`}>
                    {skuMaterialInfo !== undefined ? skuMaterialInfo.length : '0'} SKUs
                  </Typography>
                }
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <>
        {skuMaterialInfo?.map((sku, idx) => (
          <div key={sku.materialSkuNo}>
            <CertificateItemsTable
              sku={sku}
              id={idx + 1}
              key={'certificateItemsTableKey_' + sku.materialSkuNo}
              {...props}
            />
          </div>
        ))}
      </>

    </Card >
  )
}


export default CertificateItemsDetails