import React from 'react'
import { Grid } from '@mui/material'
import { Card, Typography, Box } from '@material-ui/core'
import { components } from 'cng-web-lib'
import TmpSelfCertificateTranslationText from '../../../shared/TmpSelfCertificateTranslationText'

const {
  CngGridItem
} = components



function BlanketPeriodViewComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  const { blanketPeriod } = props

  return (
    <Card style={{ padding: '0px' }}>
      <Grid container spacing={2} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', width: 'auto', backgroundColor: '#EDEDF2' }}>
        <Grid item sm={12}>
          <Grid xs={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.blanketPeriodInformation}
            </Typography>

          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', marginTop: '-10px', width: 'auto' }}>
        <CngGridItem xs={12} sm={6}>
          {FtaTranslatedTextsObject.blanketLabelFL}
        </CngGridItem>
        <CngGridItem xs={12} sm={3}>
          <Box fontSize={10}>
            {FtaTranslatedTextsObject.startDateFL}
          </Box>
          <Box fontSize={15} className='font-bolder'>
            {blanketPeriod?.startDate !== undefined ? blanketPeriod.startDate : ''}
          </Box>
        </CngGridItem>
        <CngGridItem xs={12} sm={3}>
          <Box fontSize={10}>
            {FtaTranslatedTextsObject.endDateFL}
          </Box>
          <Box fontSize={15} className='font-bolder'>
            {blanketPeriod?.endDate !== undefined ? blanketPeriod.endDate : ''}
          </Box>
        </CngGridItem>
      </Grid>
    </Card>
  )

}

export default BlanketPeriodViewComponent