import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import TmpSupplierApiUrls from 'src/apiUrls/TmpSupplierApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props,
  orderBy,
  orderDirection) {
  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()

  const columns = [
    {
      field: "name",
      title: translatedTextsObject.name,
      defaultSort: 'asc',
    },
    {
      field: "supplierAddress",
      title: translatedTextsObject.supplierAddress
    },
    {
      field: "contactName",
      title: translatedTextsObject.contactName,
    },
    {
      field: "contactEmail",
      title: translatedTextsObject.contactEmail
    },
    {
      field: "contactPhoneNumber",
      title: translatedTextsObject.contactPhoneNumber
    }
  ]


  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: TmpSupplierApiUrls.DELETE }}
              fetch={{ url: TmpSupplierApiUrls.GET }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "name": localData.name,
      "contactName": localData.contactName,
      "contactEmail": localData.contactEmail,
      "contactPhoneNumber": localData.contactPhoneNumber,
      "supplierAddress": localData.supplierAddress,

    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
