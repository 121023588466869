import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Card, Typography, CardMedia, CardContent } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import EditPrincipalProfileDialog from 'src/views/trademanagement/myproducts/components/EditPrincipalProfileDialog'
import moment from "moment";
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'



SignatureComponent.propTypes = {}

const {
  form: {
    field: {
      CngTextField,
      CngSwitchField,
      CngDateField
    }
  }, CngGridItem
} = components



function SignatureComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  const { signatureDetails, principalProfileId, signatureImage, setSignatureImage } = props
  const [isCaptureNewSignature, setIsCaptureNewSignature] = useState(false)
  const [isPrincipalDialogOpen, setPrincipalDialogOpen] = useState(false)

  const [signerKey, setSignerKey] = useState('')
  const { setValue } = useFormContext()

  const handleCaptureNewSignature = (e) => {
    setIsCaptureNewSignature(e.target.checked)
    if (!isCaptureNewSignature) {
      setPrincipalDialogOpen(true)
    } else {
      setPrincipalDialogOpen(false)
    }
  }

  function closePrincipalDialog() {
    setPrincipalDialogOpen(false)
  }

  useEffect(() => {
    if (signatureDetails.signerPrintedName !== '') {
      setValue('signerPrintedName', signatureDetails.signerPrintedName)
    }
    const momentDate = moment()
    const formattedDate = momentDate.format('DD-MMM-YYYY')
    setValue('signerCertificationDate', formattedDate)
    setSignerKey(signatureDetails.signerPrintedName)

  }, [signatureDetails])

  return (
    <Card>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', width: 'auto' }}>
        <Grid item sm={12}>
          <Grid xs={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.signatureInformation}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', marginTop: '-25px', width: 'auto' }}>
        <CngGridItem xs={12} sm={12}>
          {FtaTranslatedTextsObject.signatureLabelFL}
        </CngGridItem>
        <CngGridItem xs={12} sm={10} style={{ display: 'flex' }}>
        </CngGridItem>
        <CngGridItem xs={12} sm={2} style={{ display: 'flex' }}>
          <div style={{ marginTop: '10px' }}>
            {FtaTranslatedTextsObject.captureNewSignatureFL}
          </div>
          <div>
            <CngSwitchField
              name='captureNewSignature'
              label=''
              key='captureNewSignatureKey'
              checked={isCaptureNewSignature}
              onChange={e => handleCaptureNewSignature(e)}
            />
          </div>
        </CngGridItem>
        <Grid container spacing={1} sx={{ margin: '10px' }} >


          <CngGridItem xs={12} sm={3}>
            <CngTextField
              name='signerPrintedName'
              label={FtaTranslatedTextsObject.signerPrinterNameFL}
              key={'signerName_' + signerKey + '_key'}
              disabled={signatureDetails.signerPrintedName !== '' ? true : false}
              required
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={3}>
            <CngDateField
              name='signerCertificationDate'
              label={FtaTranslatedTextsObject.signerCertificationDateFL}
              format='DD-MMM-YYYY'
              toServerDataFormat='DD-MMM-YYYY'
              disabled={true}
            />
          </CngGridItem>


          <CngGridItem xs={12} sm={6}>
            <span>
              <Typography variant='h5' className='font-mediumBold' >
                {FtaTranslatedTextsObject.signatureFL}
              </Typography>
            </span>
            <Card raised={true} style={{ width: '100%', objectFit: 'contain' }}>
              {signatureImage !== null ?
                <CardMedia component="img"
                  height="200"
                  image={signatureImage}
                  alt="signature"
                  style={{ padding: '1em 1em 0 1em', objectFit: 'fill', width: '100%' }}
                  key={'signatureUrl_' + signerKey + '_key'}
                />
                :
                <CardContent>
                  <b>No Signature Found.</b>
                  <p>Signature has not been set. Please capture new one by visiting the principal's profile or by clicking "Capture new signature" option.</p>
                </CardContent>
              }

            </Card>
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid>
        <EditPrincipalProfileDialog
          isDialogOpen={isPrincipalDialogOpen}
          closeDialog={closePrincipalDialog}
          principalProfileId={principalProfileId}
          setSignatureImage={setSignatureImage}
          setIsCaptureNewSignature={setIsCaptureNewSignature}
          showNotification={() => { }} />
      </Grid>
    </Card>


  )
}


export default SignatureComponent