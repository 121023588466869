import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import ReplayIcon from '@material-ui/icons/Replay'
import SearchIcon from '@material-ui/icons/Search'

import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import TmpCompanyProfileApiUrls from 'src/apiUrls/TmpCompanyProfileApiUrls'
import TmpCodeMasterApiUrls from 'src/apiUrls/TmpCodeMasterApiUrls'
import TradeManagementTranslationText from '../../../shared/TradeManagementTranslationText'
import TmpSelfCertificateTranslationText from '../../../shared/TmpSelfCertificateTranslationText'



const {
  form: {
    field: { CngTextField, CngLookupAutocompleteField, CngDateTimeField },
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants



function FormFields({ ...props }) {

  const dispatch = useDispatch()

  const [generatedCertificateUuid] = useField('generatedCertificateUuid')
  const [principal] = useField('principal')
  const [fta] = useField('fta')
  const [certifier] = useField('certifier')
  const [certificationStartDate] = useField('certificationStartDate')
  const [certificationEndDate] = useField('certificationEndDate')


  const { reset } = useFormContext()




  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  const FtaTmpSelfCertTranslatedTextsObject = TmpSelfCertificateTranslationText()

  const resetForm = () => {
    console.log('reset form')

    reset({
      generatedCertificateUuid: '',
      principal: '',
      fta: '',
      certifier: '',
      certificationStartDate: '',
      certificationEndDate: ''
    })
  }

  const submitForm = () => {
    let filterProperties = []
    if (generatedCertificateUuid.value) {
      filterProperties.push({
        fieldName: 'generatedCertificateUuid',
        operatorType: 'LIKE',
        value1: generatedCertificateUuid.value
      })
    }
    if (principal.value) {
      filterProperties.push({
        fieldName: 'companyId',
        operatorType: 'EQUAL',
        value1: principal.value
      })
    }
    if (fta.value) {
      filterProperties.push({
        fieldName: 'fta',
        operatorType: 'EQUAL',
        value1: fta.value
      })
    }
    if (certifier.value) {
      filterProperties.push({
        fieldName: 'certifier',
        operatorType: 'LIKE',
        value1: certifier.value
      })
    }

    if (certificationStartDate.value) {
      filterProperties.push({
        fieldName: 'createdDate',
        operatorType: 'GREATERTHANEQUAL',
        value1: new Date(certificationStartDate.value).getTime()
      })
    }
    if (certificationEndDate.value) {
      filterProperties.push({
        fieldName: 'createdDate',
        operatorType: 'LESSERTHAN',
        value1: new Date(certificationEndDate.value).getTime()
      })
    }

    dispatch({ type: 'SET_FILTERS', payload: filterProperties })
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }


  return (
    <>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={3}>
              <CngTextField
                size='small'
                name='generatedCertificateUuid'
                label={FtaTmpSelfCertTranslatedTextsObject.certificateIdFL}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name="principal"
                label={FtaTranslatedTextsObject.principal}
                lookupProps={{
                  url: TmpCompanyProfileApiUrls.GET,
                  label: c => `${c.name}`,
                  value: 'id',
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name="fta"
                label={FtaTranslatedTextsObject.fta}
                lookupProps={{
                  url: TmpCodeMasterApiUrls.GET,
                  label: c => `(${c.code}) - ` + ` ${c.name}`,
                  value: 'code',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'TMP_FTA'
                    }
                  ]
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3}>
              <CngTextField
                size='small'
                name='certifier'
                label={FtaTmpSelfCertTranslatedTextsObject.certifierTypeFL}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngDateTimeField
                name='certificationStartDate'
                label={FtaTmpSelfCertTranslatedTextsObject.certificationStartDateFL}
                format='YYYY-MM-DD HH:mm:ss'
                size='small'
                toserverdataformat='YYYY-MM-DD HH:mm:ss'
              />
            </CngGridItem>


            <CngGridItem xs={12} sm={3}>
              <CngDateTimeField
                name='certificationEndDate'
                label={FtaTmpSelfCertTranslatedTextsObject.certificationEndDateFL}
                format='YYYY-MM-DD HH:mm:ss'
                size='small'
                toserverdataformat='YYYY-MM-DD HH:mm:ss'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3} />



            <CngGridItem xs={12} sm={3}>
              <Grid container spacing={3}>
                <CngGridItem xs={12} sm={6}>
                  <Button variant='outlined' startIcon={<ReplayIcon />}
                    size='large'
                    onClick={() => resetForm()}
                    style={{
                      color: '#e91e63',
                      width: '100%',
                      textTransform: 'none'
                    }}>
                    Clear Fields
                  </Button>
                </CngGridItem>

                <CngGridItem xs={12} sm={6}>
                  <Button variant='outlined' startIcon={<SearchIcon />}
                    onClick={submitForm}
                    color='primary' size='large'
                    style={{ width: '100%', textTransform: 'none', color: 'white', backgroundColor: 'blue' }}>
                    Search
                  </Button>
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>
    </>
  )
}


const DEFAULT_INITIAL_VALUES = {
  generatedCertificateUuid: '',
  principal: '',
  fta: '',
  certifier: '',
  certificationStartDate: '',
  certificationEndDate: ''
}

const hookFormProps = {
  initialValues: JSON.parse(JSON.stringify(DEFAULT_INITIAL_VALUES))
}

const SearchHistoryFormProperties = Object.freeze({
  hookFormProps: hookFormProps,
  FormFields: FormFields
})

export default SearchHistoryFormProperties
