import React from 'react'

import { BasePage as CngBasePage } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeManagement'
import FtaSelfCertificatePage from '../ftaselfcertificate/pages/FtaSelfCertificatePage'
import AddPage from '../ftaselfcertificate/pages/AddPage'
import TmpSelfCertificateTranslationText from '../shared/TmpSelfCertificateTranslationText'
import FtaSelfCertificateHistoryPage from '../ftaselfcertificate/pages/FtaSelfCertificateHistoryPage'
import FtaSelfCertificateHistoryViewPage from '../ftaselfcertificate/pages/FtaSelfCertificateHistoryViewPage'



function WrappedFtaSelfCertificatePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.moduleTitle}
      renderPage={(showSnackbar) => (
        <FtaSelfCertificatePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.selfCertifyProducts}
      renderPage={(showSnackbar) => (
        <AddPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}



function WrappedFtaSelfCertificateHistoryPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.selfCertHistoryModuleTitle}
      renderPage={(showSnackbar) => (
        <FtaSelfCertificateHistoryPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


function WrappedFtaSelfCertificateHistoryViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  return (
    <CngBasePage
      moduleTitle={FtaTranslatedTextsObject.selfCertHistoryViewTitle}
      renderPage={(showSnackbar) => (
        <FtaSelfCertificateHistoryViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedFtaSelfCertificatePage as FtaSelfCertificatePage,
  WrappedAddPage as SelfCertificateAddPage,
  WrappedFtaSelfCertificateHistoryPage as FtaSelfCertificateHistoryPage,
  WrappedFtaSelfCertificateHistoryViewPage as FtaSelfCertificateHistoryViewPage
}
