import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  const requiredErrorMessage = 'Required/Mandatory'
  const invalidEmail = 'This must be a valid email'
  const getErrorMessageForMaxLength = (maxLength => `This cannot be more than ${maxLength} characters`)


  return Yup.object({
    selfCertificateId: Yup.string(),
    certifierName: Yup.string().required(requiredErrorMessage).max(255, getErrorMessageForMaxLength(255)),
    certifierJobTitle: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    certifierEmailAddress: Yup.string().nullable().email(invalidEmail).max(255, getErrorMessageForMaxLength(255)),
    certifierContactNo: Yup.string().matches(/^\+(?:\d{1,3}\-)?\d{1,14}$/, 'please enter valid telephone number').nullable(),
    certifierAddress: Yup.string().nullable().max(1000, getErrorMessageForMaxLength(1000)),
    exporterName: Yup.string().required(requiredErrorMessage).max(255, getErrorMessageForMaxLength(255)),
    exporterJobTitle: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    exporterEmailAddress: Yup.string().nullable().email(invalidEmail).max(255, getErrorMessageForMaxLength(255)),
    exporterContactNo: Yup.string().matches(/^\+(?:\d{1,3}\-)?\d{1,14}$/, 'please enter valid telephone number').nullable(),
    exporterAddress: Yup.string().nullable().max(1000, getErrorMessageForMaxLength(1000)),
    isProducerConfidentiality: Yup.boolean(),
    producerName: Yup.string().when('isProducerConfidentiality', {
      is: false,
      then: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
      otherwise: Yup.string().required(requiredErrorMessage).max(255, getErrorMessageForMaxLength(255)),
    }),
    producerJobTitle: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    producerEmailAddress: Yup.string().nullable().email(invalidEmail).max(255, getErrorMessageForMaxLength(255)),
    producerContactNo: Yup.string().matches(/^\+(?:\d{1,3}\-)?\d{1,14}$/, 'please enter valid telephone number').nullable(),
    producerAddress: Yup.string().nullable().max(1000, getErrorMessageForMaxLength(1000)),
    importerName: Yup.string().required(requiredErrorMessage).max(255, getErrorMessageForMaxLength(255)),
    importerJobTitle: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    importerEmailAddress: Yup.string().nullable().email(invalidEmail).max(255, getErrorMessageForMaxLength(255)),
    importerContactNo: Yup.string().matches(/^\+(?:\d{1,3}\-)?\d{1,14}$/, 'please enter valid telephone number').nullable(),
    importerAddress: Yup.string().nullable().max(1000, getErrorMessageForMaxLength(1000)),
    blanketStartDate: Yup.date().required(requiredErrorMessage),
    blanketEndDate: Yup.date().required(requiredErrorMessage),
    signerPrintedName: Yup.string().required(requiredErrorMessage).max(255, getErrorMessageForMaxLength(255)),
    signerCertificationDate: Yup.date().required(requiredErrorMessage)
  })
}

export default ValidationSchema
