import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import TmpSelfCertificateTranslationText from '../../../shared/TmpSelfCertificateTranslationText'

CertificateofOriginViewComponent.propTypes = {
  certificateOfOrigin: PropTypes.object,
  showErrorNotification: PropTypes.func
}


function CertificateofOriginViewComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  const { certificateOfOrigin } = props

  return (
    <Card style={{ padding: '0px' }}>
      <Grid container spacing={2} style={{ padding: '15px' }}>
        <Grid item xs={12} sm={4}>
        </Grid>

        <Grid item xs={12} sm={8} style={{ textAlign: 'end' }}>
          <Typography className={'frb-card-label-large-grey'} >
            {FtaTranslatedTextsObject.certificateIdFL}:
            <b className='ml-5 dark'>
              {certificateOfOrigin?.certificateId !== undefined ? certificateOfOrigin.certificateId : ''}
            </b>
            {'   | Certifier type '}
            <b className='ml-5 dark'>
              {certificateOfOrigin?.certifierType !== undefined ? certificateOfOrigin.certifierType.toUpperCase() : ''}
            </b>
          </Typography>
        </Grid>
      </Grid >

      <Grid container spacing={2} style={{ backgroundColor: '#EDEDF2', padding: '10px' }}>
        <Grid item xs={12} sm={3} >
          <Typography style={{ textAlign: 'start' }}>
            <b>{FtaTranslatedTextsObject.certificateOfOriginFL}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} style={{ backgroundColor: '#EDEDF2', textAlign: 'end', fontWeight: 'bold', fontSize: 'x-large', color: '#8996AF' }}>
          {certificateOfOrigin?.ftaName !== undefined ? certificateOfOrigin.ftaName : ''}
          {'\u2022'}
          {certificateOfOrigin?.ftaCode !== undefined ? certificateOfOrigin.ftaCode : ''}
        </Grid>
      </Grid >
    </Card >

  )
}

export default CertificateofOriginViewComponent