import React from 'react'
import { Card, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ViewDetailedPanel from '../ViewDetailedPanel'



function GeneratedCertificateViewComponent(props) {
  const { selfCertificatePayload, displayDate, displayTime, signatureImage } = props

  return (
    <Card style={{ padding: '25px', backgroundColor: '#F7FAFF' }}>
      <Typography variant='h4' className='font-bolder'>
        FTA self-certificate generated
        <CheckCircleIcon fontSize='medium' color='secondary' />
      </Typography>
      <Typography variant='subtitle1'>
        Certified on {displayDate} at {displayTime}
      </Typography>

      <ViewDetailedPanel
        viewPanelSelfCertificate={selfCertificatePayload}
        signatureImage={signatureImage}
      />
    </Card>
  )
}




export default GeneratedCertificateViewComponent