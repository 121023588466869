import React from 'react'
import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_TradeManagement'
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'


GeneratedCertificateButtonSection.propTypes = {}
const {
  CngGridItem
} = components


function GeneratedCertificateButtonSection(props) {
  const { downloadPDFOnClick } = props
  const history = useHistory()
  const FtaTmpSelfCertTranslatedTextsObject = TmpSelfCertificateTranslationText()


  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={6} sm={6}>
        <Button
          variant='contained'
          className='ng-button-filled-secondary2 originalText'
          type='button'
          size='large'
          onClick={() =>
            history.push(pathMap.SELF_CERTIFICATE_HISTORY)
          }
          sx={{ borderRadius: '8px !important' }}>
          {FtaTmpSelfCertTranslatedTextsObject.backToSelfCertHistory}
        </Button>

      </Grid>

      <CngGridItem item xs={6} sm={6}>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            variant='contained'
            className='button-light-blue originalText'
            type='button'
            size='large'
            style={{ borderRadius: '8px', marginLeft: '5px' }}
            onClick={() => {
              downloadPDFOnClick()
            }}
          > {FtaTmpSelfCertTranslatedTextsObject.downloadPdfBL}
          </Button>
        </Box>
      </CngGridItem>
    </Grid>
  )

}

export default GeneratedCertificateButtonSection