import { constants } from 'cng-web-lib'

const {
  locale: { Namespace: CngNamespace }
} = constants

const Namespace = {
  ...CngNamespace,

  COMPLIANCE: 'compliance',
  CARBON_FOOTPRINT_CALCULATOR: 'carbon-footprint-calculator',
  CALISTA_UI_COMPONENT: 'calista-ui-component',
  CALISTA_ADVISORY: 'calista-advisory',
  CARRIER_ENROLL: 'carrier-enroll',
  ESSDOCS_SSO: 'essdocs-sso',
  MANAGE_PERMIT: 'manage-permit',
  NEW_PERMIT: 'new-permit',
  PERMIT_TYPE: 'permit-type',
  POLLING: 'polling',
  FREIGHT_BOOKING: 'freight-booking',
  FREIGHT_BOOKING_VALIDATION: 'freight-booking-validation',
  TCAL_VS_VESSEL: 'tcal-vs-vessel',
  TCAL_VS_VOYAGE: 'tcal-vs-voyage',
  TCAL_VS_MANAGE_SCHEDULE: 'tcal-vs-manage-schedule',
  TCAL_VS_MANAGE_SCHEDULE_VALIDATION: 'tcal-vs-manage-schedule-validation',
  TCAL_VS_MANAGE_CARRIERS: 'tcal-vs-manage-carriers',
  SEARCH_SCHEDULE: 'search-schedule',
  SUBSCRIPTION: 'subscription',
  TCAL_PARTY_CARRIER_CODE: 'tcal-party-carrier-code',
  TCAL_AS_USER_GROUP_MASTER: 'tcal_as_user_group_master',
  TTFB_TPR_PARTY: 'ttfb-tpr-party',
  USER_FOR_USER_GROUP: 'user-for-user-group',
  ACCESS_LEVEL_MASTER: 'access-level-master',
  ACCESS_LEVEL_RULE: 'access-level-rule',
  ACCESS_LEVEL_COMPANY: 'access-level-company',
  ACCESS_LEVEL_COMPANY_CUSTOM_RULE: 'access-level-company-custom-rule',
  ACCESS_LEVEL_USER: 'access-level-user',
  NBS_CHARGE_CODE_MAPPING: 'nbs-charge-code-mapping',
  NBS_BILLING_CONFIG: 'nbs-billing-config',
  NBS_FILE: 'nbs-file',
  SHIPPER_REQUEST_TYPE: 'shipper-request-type',
  COMPANY_FOR_SHIPPER_REQUEST: 'company-for-shipper-request',
  COMPANY_FOR_USER_GROUP: 'company-for-user-group',
  USER_FOR_SHIPPER_REQUEST: 'user-for-shipper-request',
  CALISTA_PARTY_CONFIG: 'calista-party-config',
  NOTIFICATION_SUBSCRIPTION: 'notification-subscription',
  NOTIFICATION_TRIGGER: 'notification-trigger',
  REPORT_MANAGEMENT: 'report-management',
  REPORT_SCHEDULER: 'report-scheduler',
  REPORT_SCHEDULER_CUSTOMER: 'report-scheduler-customer',
  GENERATED_REPORT: 'generated-report',
  GENERATED_REPORT_CUSTOMER: 'generated-report-customer',
  CALISTA_PARTY_COMPANY_INFO: 'calista-party-company-info',
  //REPORT_DOWNLOAD: 'report-download',
  PARTY_MAPPING: 'party-mapping',
  BATCH_BOOKING_REQUEST: 'batch-booking-request',
  BATCH_MILESTONE: 'batch-milestone',
  BATCH_SFTP_PROCESS: 'batch-sftp-process',
  CODE_MAPPING: 'code-mapping',
  PORT_MAPPING: 'port-mapping',
  PLATFORM_PORT_MAPPING: 'platform-port-mapping',
  BATCH_DATA_CONFIG: 'batch-data-config',
  TRANSACTION_LOG: 'transaction-log',
  EVGM: 'evgm',
  SCO_JOB_CONFIG: 'sco-jobconfig',
  BOOKING_SYNC_LOG: 'booking-sync-log',
  SCO_JOB: 'sco-job',
  SCO_INV_MGMT: 'sco-inv-mgmt',
  SCO_INV_REPORT: 'sco-inv-mgmt-report',
  PING: 'ping',
  HELPDESK: 'helpdesk',
  VS_MIGRATION_SYNC_LOG: 'vs-migration-sync-log',
  RICE_STOCK: 'rice-stock',
  SINOTRANS_REGISTRATION: 'sinotrans-registration',
  CLOGS_CONFIG: 'clogs-config',
  COMPANY_PREFERENCE: 'company-preference',
  USER_PREFERENCE: 'user-preference',
  SHIPPING_INSTRUCTION: 'shipping-instruction',
  SEQUENCE: 'sequence',
  JSON_SCHEMA: 'json-schema',
  BILLING_AUDIT: 'billing-audit',
  CONTAINER_TYPE: 'container-type',
  CUSTOM_USER_ACCOUNT: 'custom-user-account',
  SHIPPING_INSTRUCTION_VALIDATION: 'shipping-instruction-validation',
  MODULE: 'module',
  ADMINLOG: 'adminlog',
  SCO_ADMIN: 'scoAdmin',
  NMOT: 'nmot',
  NMOT_VALIDATION: 'nmot-validation',
  ECO_CALCULATOR: 'eco-calculator',
  METHODOLOGY: 'methodology',
  FORWARD_HUB: 'cal-forwardhub',
  CEC_ACCOUNT: 'cecAccount',
  CEC_UOM_CONVERSION: 'cecUomConversion',
  CEC_DO_UOM_MAPPING: 'cecDoUomMapping',
  CEC_POSTCODE_CONFIG: 'cecPostcodeConfig',
  CEC_PARTY_CONFIG: 'cec-party-config',
  CEC_QUOTATION: 'cec-quotation',
  CEC_REPORT: 'cec-report',
  PING_EVENT_SUBSCRIPTION: 'ping-event-subscription',
  SCO_RATE_MGMT: 'sco-rate-mgmt',
  SHIPPING_INSTRUCTION_REQUEST: 'shipping-instruction-request',
  CONNECTOR_APPS: 'connector-apps',
  COMMON: 'common',
  COMMON_CONFIG: 'common-config',
  CEC_COMMON_CITY_MAP: 'common-city-mapping',
  NBS_MAPPING: 'nbs-mapping',
  TMP_CODE_MASTER: 'tmpCodeMaster',
  TMP_COMPANY_PROFILE: 'tmpCompanyProfile',
  TMP_SUPPLIER: 'tmpSupplier',
  TMP_COMPONENT: 'tmpComponent',
  TMP_MASS_UPLOAD: 'tmpMassUpload',
  TMP_SYS_CONFIG: 'tmpSysConfig',
  TMP_DPS: 'tmp-denied-party-search',
  SHIPMENT_PROVIDERS: 'shipment_providers',
  TRADE_MANAGEMENT: 'trade-management',
  SCO_JM_CUSTOM_ATTR: 'sco-jm-custom-attr',
  TMP_COUNTRY_MAP: `tmpCountryMap`,
  TMP_MASTER_UPLOAD: `tmpMasterUpload`,
  DELIVERY_ORDERS: 'delivery-orders',
  ECOMMERCE_UPLOAD: 'ecommerce-upload',
  ECOMMERCE_LABEL: 'ecommerce-label',
  TMP_SELF_CERT: `tmpself-cert`

}

export default Namespace
