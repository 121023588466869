import React from 'react'
import {
  Box,
  makeStyles
} from '@material-ui/core'
import { components, constants } from 'cng-web-lib'


const {
  button: { CngButton },
  form: {
    field: { CngTextField },
  },
} = components

const {
  CodeMaintenanceType,
  apiUrl: { CodeMaintenanceApiUrls },
  filter: { EQUAL }
} = constants

const useStyles = makeStyles(theme => ({
  lightText: { color: theme.palette.text.textSecondary },
  readOnly: {

  },
  input: {

  }
}))

function Field({
  label, render, component, readOnly,
  codeLookup, onLookup, disableActive,
  ...props
}) {

  const classes = useStyles()

  return <Box className={classes.input}>
    <Box component={component} label={label} {...props} />
  </Box>
}

Field.defaultProps = {
  component: CngTextField
}

export { Field }
