import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Card, Typography, Box } from '@material-ui/core'
import { components } from 'cng-web-lib'
import RadioToggleButtonGroup from './RadioToggleButtonGroup'
import TmpSelfCertificateTranslationText from '../../shared/TmpSelfCertificateTranslationText'

CertificateOfOriginComponent.propTypes = {
  generateSelfCertificate: PropTypes.object,
  showNotification: PropTypes.func
}

const {
  form: {
    field: {
      CngTextField
    }
  }, CngGridItem
} = components

function CertificateOfOriginComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()

  const {
    ftaName,
    ftaCode,
    certificateId
  } = props






  return (
    <Card style={{ display: 'flex' }}>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px' }}>
        <CngGridItem xs={12} sm={4}>
          {/* <img src=''> */}
        </CngGridItem>

        <CngGridItem xs={12} sm={8} >
          <Typography className={'frb-card-label-large-grey'} style={{ textAlign: 'end' }}>
            {FtaTranslatedTextsObject.certificateIdFL}:
            <b>
              {certificateId}
            </b>
          </Typography>
          <CngGridItem xs={12} sm={12} shouldHide={true} >
            <CngTextField
              name='certificateId'
              label='certificateId'
              key='certificateIdKey'
              value={certificateId}
            />
          </CngGridItem>
        </CngGridItem>

        <CngGridItem xs={12} sm={3} style={{ backgroundColor: '#EDEDF2' }}>
          <Typography style={{ textAlign: 'start', fontWeight: 'bold' }}>
            {FtaTranslatedTextsObject.certificateOfOriginFL}
          </Typography>

          <Box fontSize={10} paddingBottom={2}>
            <span style={{ color: 'red' }}>*</span>
            {' Mandatory fields '}
          </Box>
        </CngGridItem>
        <CngGridItem xs={12} sm={9} style={{ backgroundColor: '#EDEDF2', textAlign: 'end', fontWeight: 'bold', fontSize: 'x-large', color: '#8996AF' }}>
          {ftaName} {'\u2022'} {ftaCode}
        </CngGridItem>
        <CngGridItem xs={12} sm={12} shouldHide={true} >
          <CngTextField
            name='ftaName'
            label='ftaName'
            key='ftaNameKey'
            value={ftaName}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={12} shouldHide={true} >
          <CngTextField
            name='ftaCode'
            label='ftaCode'
            key='ftaCodeKey'
            value={ftaCode}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={2}>
          <Box paddingTop={2}>
            <p>{FtaTranslatedTextsObject.certifierTypeFL} <span style={{ color: 'red' }}>*</span></p>
          </Box>
        </CngGridItem>
        <CngGridItem xs={12} sm={10}>
          <RadioToggleButtonGroup
            name={'certifierType'}
            defaultValue={'Exporter'}
            disabled={false}
            options={[{
              value: 'Exporter', label: 'Exporter'
            }, {
              value: 'Importer', label: 'Importer'
            }, {
              value: 'Producer', label: 'Producer'
            }
            ]}
            key={`certifierTypeKey`}
          />
        </CngGridItem>
      </Grid>
    </Card>
  )
}

export default CertificateOfOriginComponent