const TmpSelfCertificateKeys = {
  SELFCERTIFICATETITLE: 'selfCertificateTitle',
  SELFCERTIFICATEHISTORYTITLE: 'selfCertificateHistoryTitle',
  SELFCERTIFICATEHISTORYVIEWTITLE: 'selfCertificateHistoryViewTitle',
  SELFCERTIFYTITLE: 'selfCertify',
  SectionTitles: {
    SELF_CERTIFY_PRODUCTS: 'sectionTitle.selfCertifyProducts',
    CERTIFIER_INFORMATION: 'sectionTitle.certifierInformation',
    EXPORTER_INFORMATION: 'sectionTitle.exporterInformation',
    PRODUCER_INFORMATION: 'sectionTitle.producerInformation',
    IMPORTER_INFORMATION: 'sectionTitle.importerInformation',
    SKU_MATERIALS_INFORMATION: 'sectionTitle.skuMaterialsTitle',
    BLANKET_PERIOD_INFORMATION: 'sectionTitle.blanketPeriodTitle',
    SIGNATURE_INFORMATION: 'sectionTitle.signatureTitle',
  },
  TableLabels: {
    MATERIAL_NO_SKU: 'tableLabels.materialNoSku',
    HS_CODE_DESCRIPTION: 'tableLabels.hsCodeDescription',
    PRINCIPAL: 'tableLabels.principal',
    FTA: 'tableLabels.fta',
    ROO_QUALIFICATIONS: 'tableLabels.rooQualification',
    STATUS: 'tableLabels.status',
  },
  ButtonLabels: {
    DISCARD: 'buttonsLabels.discard',
    BACK: 'buttonsLabels.back',
    CONFIRM_REVIEW: 'buttonsLabels.confirmReview',
    GENERATE_CERTIFICATE: 'buttonsLabels.generateCertificate',
    VIEW_FTA_SELF_CERT_HISTORY: 'buttonsLabels.viewFtaSelfCertHistory',
    DOWNLOAD_PDF: 'buttonsLabels.downloadPdf',
    BACK_TO_SELF_CERT_HISTORY: 'buttonsLabels.backToSelfCertHistory'
  },
  FieldLabels: {
    MATERIAL_NO_SKU: 'fieldLabels.materialNoSku',
    HSCODE: 'fieldLabels.hsCode',
    HSDESCRIPTON: 'fieldLabels.hsDescription',
    PRINCIPAL: 'fieldLabels.principal',
    FTA: 'fieldLabels.fta',
    CERTIFICATE_ID: 'fieldLabels.certificateId',
    CERTIFICATE_OF_ORIGIN: 'fieldLabels.certificateOfOrigin',
    CERTIFIER_TYPE: 'fieldLabels.certifierType',
    ENTITY_NAME: 'fieldLabels.entityName',
    JOB_TITLE: 'fieldLabels.jobTitle',
    EMAIL_ADDRESS: 'fieldLabels.emailAddress',
    CONTACT_NO: 'fieldLabels.contactNo',
    ADDRESS: 'fieldLabels.address',
    EXPORTER_IF_DIFFERENT: 'fieldLabels.exporterIfDifferent',
    SAME_AS_CERTIFIER_DETAILS: 'fieldLabels.sameAsCertifierDetails',
    PRODUCER_IF_DIFFERENT: 'fieldLabels.producerIfDifferent',
    PRODUCER_CONFIDENTIALITY: 'fieldLabels.producerConfidentiality',
    PRODUCER_DECLARE_VARIOUS: 'fieldLabels.producerDeclareVarious',
    IMPORTER_DECLARE_UNKNOWN: 'fieldLabels.importerDeclareUnknown',
    IMPORTER_DECLARE_VARIOUS: 'fieldLabels.importerDeclareVarious',
    PRODUCT_DESCRIPTION: 'fieldLabels.productDescription',
    BASE_UNIT: 'fieldLabels.baseUnit',
    FOB_PRICE: 'fieldLabels.fobPrice',
    BLANKET_LABEL: 'fieldLabels.blanketLabel',
    START_DATE: 'fieldLabels.startDate',
    END_DATE: 'fieldLabels.endDate',
    SIGNATURE_LABEL: 'fieldLabels.signatureLabel',
    CAPTURE_NEW_SIGNATURE: 'fieldLabels.captureNewSignature',
    SIGNATURE: 'fieldLabels.signature',
    SIGNER_PRINTED_NAME: 'fieldLabels.signerPrintedName',
    SIGNER_CERTIFICATION_DATE: 'fieldLabels.signerCertificationDate',
    CERTIFICATION_START_DATE: 'fieldLabels.certificationStartDate',
    CERTIFICATION_END_DATE: 'fieldLabels.certificationEndDate'
  }
}
export default TmpSelfCertificateKeys