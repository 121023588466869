import React from 'react'
import { Grid } from '@mui/material'
import { Card, Typography, Box, CardMedia, CardContent } from '@material-ui/core'
import { components } from 'cng-web-lib'
import TmpSelfCertificateTranslationText from '../../../shared/TmpSelfCertificateTranslationText'


const {
  CngGridItem
} = components


function SignatureViewComponent(props) {
  const FtaTranslatedTextsObject = TmpSelfCertificateTranslationText()
  const { signature, signatureImage } = props

  return (
    <Card>
      <Grid container spacing={2} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', width: 'auto', backgroundColor: '#EDEDF2' }}>
        <Grid item sm={12}>
          <Grid xs={6}>
            <Typography variant='h5' className='font-bolder'>
              {FtaTranslatedTextsObject.signatureInformation}
            </Typography>

          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ margin: '0px', border: '1px' }} style={{ padding: '0px', width: 'auto', wordBreak: 'break-word' }}>
        <CngGridItem xs={12} sm={12}>
          {FtaTranslatedTextsObject.signatureLabelFL}
        </CngGridItem>


        <Grid container spacing={1} sx={{ margin: '10px' }} >
          <CngGridItem xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.signerPrinterNameFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {signature?.signerPrinterName !== undefined ? signature.signerPrinterName : ''}
            </Box>
          </CngGridItem>




          <CngGridItem xs={12} sm={3}>
            <Box fontSize={10}>
              {FtaTranslatedTextsObject.signerCertificationDateFL}
            </Box>
            <Box fontSize={15} className='font-bolder'>
              {signature?.signerCertificationDate !== undefined ? signature.signerCertificationDate : ''}
            </Box>
          </CngGridItem>

          <CngGridItem xs={12} sm={6}>
            <span>
              <Typography variant='h7' className='font-mediumBold' >
                {FtaTranslatedTextsObject.signatureFL}
              </Typography>
            </span>
            <Card raised={true} style={{ width: '100%', objectFit: 'contain' }}>
                          {signatureImage !== null ?
                            <CardMedia component="img"
                              height="200"
                              image={signatureImage}
                              alt="signature"
                              style={{ padding: '1em 1em 0 1em', objectFit: 'fill', width: '100%' }}

                            />
                            :
                            <CardContent>
                              <b>No Signature Found.</b>
                            </CardContent>
                          }

                        </Card>
          </CngGridItem>
        </Grid>
      </Grid>
    </Card>
  )
}

export default SignatureViewComponent