import React, { useEffect } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { components, useServices } from 'cng-web-lib'

import { createStore } from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import Utils from 'src/shared/Utils'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import FTASelfCertificateSearchForm from '../table/form/FTASelfCertficateSearchForm'
import { FTASelfCertificateTable } from '../table/FTASelfCertificateTable'



const {
  CngGridItem,
  table: { useDefaultNotification }
} = components



const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    padding: '20px'
  },
  sectionMain: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    padding: '20px'
  }
}))



const initialState = {
  pageSize: 10,
  pageNumber: 1,
  sortType: 'desc',
  sortColumn: 'createdDate',
  totalPages: 0,
  totalRecords: 0,
  selfCertificate: [],
  deliveryOrderRefNo: '',
  tableKey: null,
  isLoading: false,
  hasError: false,
  filters: [],
  triggerFetch: true,
  selectedRows: [],
  singlePrintRef: '',
  triggerPrint: false
}

const stateReducer = (state, { type, payload }) => {
  state = state ?? initialState;
  switch (type) {
    case 'SET_PAGE_SIZE':
      return {
        ...state, pageSize: payload
      }
    case 'SET_PAGE_NUMBER':
      return {
        ...state, pageNumber: payload
      }
    case 'SET_TOTAL_RECORDS':
      return {
        ...state, totalRecords: payload
      }
    case 'SET_TOTAL_PAGES':
      return {
        ...state, totalPages: payload
      }
    case 'SET_SORT_COLUMN':
      return {
        ...state, sortColumn: payload
      }
    case 'SET_SORT_TYPE':
      return {
        ...state, sortType: payload
      }
    case 'SET_SELF_CERTIFICATE':
      return {
        ...state, selfCertificate: payload
      }
    case 'SET_FILTERS':
      return {
        ...state, filters: payload
      }
    case 'SET_LOADING':
      return {
        ...state, isLoading: payload
      }
    case 'SET_HAS_ERROR':
      return {
        ...state, hasError: payload
      }
    case 'SET_TRIGGER_FETCH':
      return {
        ...state, triggerFetch: payload
      }
    case 'SET_SELECTED_ROWS':
      return {
        ...state, selectedRows: payload
      }
    case 'SET_RESET':
      return initialState

    case 'SET_SINGLE_PRINT':
      return {
        ...state, singlePrintRef: payload
      }
    case 'SET_TRIGGER_PRINT':
      return {
        ...state, triggerPrint: payload
      }
    default:
      return state
  }
}

const store = createStore(stateReducer)

function FtaSelfCertificatePage(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { securedSendRequest } = useServices()

  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  // const [dialogContent, setDialogContent] = useState({})


  const {
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch
  } = useSelector(({
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    filters,
    triggerFetch

  }) => ({
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch
  }))

  const {
    showNotification
  } = props

  // const {
  //   error: showError Notification
  // } = useDefaultNotification(showNotification)


  // Locale
  // const FtaTranslatedTextsObject = TradeManagementTranslationText()


  const fetchSelfCertificate = (formData) => {

    dispatch({ type: 'SET_LOADING', payload: true })

    const sortProperties = sortColumn && sortType ? [{
      'sortField': sortColumn, 'sortType': sortType
    }] : []

    // Search payload
    const searchPayload = {
      'baseFilterDTO': {
        'filterType': 'AND',
        'filterProperties': filters,
        'sortProperties': sortProperties,
        'page': pageNumber - 1,
        'size': pageSize
      }
    }

    console.log('payload :: ', searchPayload)

    // API call
    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.SELF_CERTIFICATE_LIST,
      searchPayload,
      (response) => {
        const data = response?.data?.content || []
        const totalPages = response?.data?.totalPages || 0
        const totalCount = response?.data?.content?.length || 0
        dispatch({ type: 'SET_SELF_CERTIFICATE', payload: data })
        dispatch({ type: 'SET_TOTAL_PAGES', payload: totalPages })
        dispatch({ type: 'SET_TOTAL_RECORDS', payload: totalCount })
        dispatch({ type: 'SET_LOADING', payload: false })

      },
      (error) => {
        console.error(error)
      },
      // On complete
      () => {
        // Hide data loader overlay
        dispatch({ type: 'SET_LOADING', payload: false })
      },
      {}
    )

  }



  useEffect(() => {
    if (triggerFetch) {
      fetchSelfCertificate()
      dispatch({ type: 'SET_TRIGGER_FETCH', payload: false })
    }
  }, [triggerFetch])


  return (


    <Grid container spacing={5}>
      <CngGridItem item xs={12}>
        <Box className={`cng-details-page__section ${classes.sectionBlueBg}`}>
          <FTASelfCertificateSearchForm
            showNotification={showNotification}
          />
        </Box>
      </CngGridItem>

      <Grid item xs={12}>
        <FTASelfCertificateTable />

        {/* <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => {
          triggerPrint ? massPrintCloseDialog() : setConfirmDialogOpen(false)
        }}
        confirmDialog={() => {
          triggerPrint ? massPrint() : setConfirmDialogOpen(false)
        }}
        cancelMsg={dialogContent.cancelMsg}
        content={dialogContent.content}
        title={dialogContent.title}
        okMsg={'OK'}
      /> */}
      </Grid>
    </Grid>
  )
}

FtaSelfCertificatePage.propTypes = {
  showNotification: PropTypes.func
}

export default (props) => (<Provider store={store}>
  <FtaSelfCertificatePage showNotification={props.showNotification} />
</Provider>);
