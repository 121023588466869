import React from 'react'
import { components, constants } from 'cng-web-lib'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import SearchHistoryFormProperties from './SearchHistoryFormProperties'


const {
  form: {
    CngForm
  }
} = components

const {
  FormState
} = constants



function FTASelfCertficateHistorySearchForm({ showNotification }) {
  const {
    filters
  } = useSelector(({ filters }) => ({ filters }))


  let formData = {
    generatedCertificateUuid: '',
    principal: '',
    fta: '',
    certifier: '',
    certificationStartDate: '',
    certificationEndDate: ''
  }


  if (filters != null && filters.length > 0) {
    if (_.find(filters, { fieldName: 'generatedCertificateUuid' })) {
      formData.generatedCertificateUuid = _.find(filters, { fieldName: 'generatedCertificateUuid' }).value1
    }
    if (_.find(filters, { fieldName: 'principal' })) {
      formData.principal = _.find(filters, { fieldName: 'principal' }).value1
    }
    if (_.find(filters, { fieldName: 'fta' })) {
      formData.fta = _.find(filters, { fieldName: 'fta' }).value1
    }

    if (_.find(filters, { fieldName: 'certifier' })) {
      formData.certifier = _.find(filters, { fieldName: 'certifier' }).value1
    }

    if (_.find(filters, { fieldName: 'certificationStartDate', operatorType: 'GREATERTHANEQUAL' })) {
      formData.certificationStartDate = moment(_.find(filters, {
        fieldName: 'createdDate',
        operatorType: 'GREATERTHANEQUAL'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (_.find(filters, { fieldName: 'certificationEndDate', operatorType: 'LESSERTHAN' })) {
      formData.certificationEndDate = moment(_.find(filters, {
        fieldName: 'createdDate',
        operatorType: 'LESSERTHAN'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }

  }


  return (
    <CngForm
      formState={FormState.COMPLETED}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <SearchHistoryFormProperties.FormFields
        disabled={false}
        showNotification={showNotification}
      />}
      hookFormProps={{
        initialValues: formData,
        mode: 'all'
      }}
      renderButtonSection={() => undefined}
    />
  )
}

export default FTASelfCertficateHistorySearchForm
